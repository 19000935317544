import React from 'react'


const PaymentOpened = () => {


    return (
        <>
            <p>Se você ainda não pagou o boleto bancário, troque a forma de pagamento para cartão de crédito e assista o exame imediatamente.</p>
            <p>suporte técnico</p>
            <p>'Se você tiver alguma dúvida, por favor entre em contato com o nosso</p>
            <p>Após o pagamento, o boleto bancário pode levar até 3 dias para ser compensado.</p>
            <p>Ver boleto</p>
        </>
    )
}

export default PaymentOpened