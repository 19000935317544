const { REACT_APP_ENV } = process.env

/**
 * Env keys
 */
const DEBUG_MODE_KEY = '_DEBUG_MODE_'
const LIVE_MODE_KEY = '_LIVE_MODE_'

export const DEBUG_MODE = localStorage.getItem(DEBUG_MODE_KEY) === 'true'

export const LIVE_MODE = localStorage.getItem(LIVE_MODE_KEY) === 'true'

export const DEV_MODE = REACT_APP_ENV === 'localhost' || REACT_APP_ENV === 'staging'


/**
 * Device
 */

export function isMobile() { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
        return true
    }
    else {
        return false
    }
}

/**
 * Debug mode
 */
const enableDebugMode = () => localStorage.setItem(DEBUG_MODE_KEY, true)
const disableDebugMode = () => localStorage.removeItem(DEBUG_MODE_KEY)

/**
 * Live mode
 */
export const enableLiveMode = () => localStorage.setItem(LIVE_MODE_KEY, true)
export const disableLiveMode = () => localStorage.removeItem(LIVE_MODE_KEY)

function addShakeEventToEnableLiveMode() {
    const Shake = require('shake.js')
    const shakeEvent = new Shake({
        threshold: 15,
        timeout: 1000
    })

    shakeEvent.start()

    window.addEventListener('shake', () => {
        const func = LIVE_MODE ? disableLiveMode : enableLiveMode
        func()
        alert(`Live mode => [${!LIVE_MODE}]`)
        document.location.reload()
    })
}

/**
 * Hosts
 */
const HOSTS = {
    localhost: 'https://stg-assista.vlab.live',
    staging: 'https://stg-assista.vlab.live',
    production: 'https://assista.vlab.live'
}

const SENTRY_ENDPOINTS = {
    staging: 'https://60e1c29bbafe469b9a6989750c3864e2@sentry.io/1280330',
    production: 'https://011558194ece47538cdee6442fb90aef@sentry.io/1280330'
}

export const SENTRY_URL = SENTRY_ENDPOINTS[REACT_APP_ENV]

export const CURRENT_HOST = HOSTS[REACT_APP_ENV]


/**
 * Function to add env functions
 */
export function registerEnvUtils() {
    window._VLAB_ = {
        enableDebugMode,
        disableDebugMode,
        enableLiveMode,
        disableLiveMode
    }

    if (DEV_MODE) {
        addShakeEventToEnableLiveMode()
    }
}
