import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap || "0"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
  border: ${({ border }) => border || "none"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  position: ${({ position }) => position || "static"};
  top: ${({ top }) => top || "auto"};
  left: ${({ left }) => left || "auto"};
  right: ${({ right }) => right || "auto"};
  bottom: ${({ bottom }) => bottom || "auto"};
  z-index: ${({ zIndex }) => zIndex || "auto"};
  overflow-y: ${({ overflow }) => overflow || "visible"};
  cursor: ${({ cursor }) => cursor || "auto"};
  transition: ${({ transition }) => transition || "none"};
  transform: ${({ transform }) => transform || "none"};
  &:hover {
    transform: ${({ hoverTransform }) => hoverTransform || "none"};
  }
  &:active {
    transform: ${({ activeTransform }) => activeTransform || "none"};
  }
  &:focus {
    transform: ${({ focusTransform }) => focusTransform || "none"};
  }
  &:disabled {
    transform: ${({ disabledTransform }) => disabledTransform || "none"};
  }
`;

export default FlexContainer;
