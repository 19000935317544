import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import RatingComponent from './RatingComponent';
import { LightLabel, RecordedLabel } from '../../login/components/labels/label-access';
import CloseButton from './CloseButton';
import { Box } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { ShareButton } from '../../login/components/buttons';
import { FormControl } from '@material-ui/core';
import { ratingUser } from '../../../lib/api';
import { getMobileOperatingSystem } from '../../../utils/mobile-detect';
import { PatientContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

const InputRating = styled.input`
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  width: 100%;
  height: 42px;
  border: 1px solid #DDE5EC;
  border-radius: 8px;
  margin-bottom: 14px;
  justify-content: center;
  padding-left: 6px;
  letter-spacing: 1px;
  transition: border-color 0.3s ease;
  color: #020523;
  &:placeholder {
    color: #020523;
  }
  &:hover {
    border: 1px solid #B7C6D1;
  }
  &:hover::placeholder {
    color: #8B98A3;
  }
  &:active {
    border: 1px solid #B7C6D1;
  }
  &:focus {
    outline: none;
    border: 1px solid #8B98A3;
    color: #020523;
  }
  &:not(:hover)::placeholder {
    color: #8B98A3;
  }
`;

const SupportLabel = styled.p`
  color: #020523;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;    
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  display: flex;
  justify-content: center;
  letter-spacing: 0.5px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const GraySupport = styled.p`
    height: 28px;
    color: #51525F;
    margin-top: 16px;
    text-align: flex-start;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12.9px;
    line-height: 14px;
    letter-spacing: 0.5px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComponent({ open, type, onShare, supportLabelError, globalPhone }) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(open);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const { settings } = useContext(PatientContext)
  const { loginButton } = settings
  const platform = getMobileOperatingSystem()
  const { t, i18n } = useTranslation();
  const mixpanel = useMixpanel()

  let storeUrl

  if (platform === 'iOS') {
    storeUrl = 'https://apps.apple.com/app/apple-store/id1583958416?pt=123391444&ct=vbaby-assista&mt=8'
  }
  else if (platform === 'Android') {
    storeUrl = 'https://play.google.com/store/apps/details?id=com.vlab.vbaby&utm_source=vbaby-assista&utm_content=buttom'
  }

  const handleSupportMessage = () => {
    const phoneNumber = '551124245299'; 
    let message;
    
    if (supportLabelError) {
      message = t('supportMessages.messageDownloadUser');;
    } else {
      message = t('supportMessages.messageViewUser');
    }
    
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
    mixpanel.track('CALL_SUPPORT_SOLUTION_ASSISTA', { 
      distinct_id: globalPhone,
      'CALL_SUPPORT': true,
      'PHONE_SUPPORT': phoneNumber,
      'URL_SUPPORT': whatsappUrl,
      'MESSAGE_SUPPORT': message,
    });
  };
  
  const handleApp = () => {
    window.dataLayer.push({
      event: 'go-to-app',
    });

    window.open(storeUrl, '_blank')
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setIsButtonDisabled(selectedValue <= 3 && inputValue === '');
  };

  const handleRatingSelect = (value) => {
    setSelectedValue(value);
    setIsButtonDisabled(value <= 3 && inputValue === '');
  };

  const handleClose = () => {
    window.dataLayer.push({
      event: 'close-app-modal',
    });
    
    setIsOpen(false);
  };

  const handleRatingSubmit = async () => {
    try {
      const response = await ratingUser({ rate: selectedValue, feedback: inputValue }, onShare);  
      setIsOpen(false);
    }
    catch {
      console.error()
    }
    setIsOpen(false);

  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  let dialogContent;

  if (type === 'rating') {
    dialogContent = (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center"  width="100%">
          <Box flexGrow={1} padding={'0' }>
            <RecordedLabel>
            {t('dialog.experience')}
            </RecordedLabel>
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>

        <Box style={{width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>        
          <FormControl 
            style={{width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
          >
            <RatingComponent onRatingSelect={handleRatingSelect} />
            <InputRating 
              name="rating" 
              type="text"
              placeholder={t('dialog.leaveComment')}
              disabled={selectedValue === null} 
              required={selectedValue <= 3} 
              onChange={handleInputChange} 
            />
            <ShareButton 
              color={loginButton ? loginButton.backgroundColor : "#0166FF"}
              fontColor={loginButton ? loginButton.textColor : "#fff"} 
              onClick={handleRatingSubmit} 
              disabled={!selectedValue || isButtonDisabled}>
              {t('buttons.sendFeedback')}
            </ShareButton>
          </FormControl>
        </Box>
      </Box>
    );
  } else if (type === 'support') {
    dialogContent = (
      <Box>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center"  width="100%">
            <Box flexGrow={1}>
            {supportLabelError ? (
              <SupportLabel>{supportLabelError}</SupportLabel>
            ) : (
              <SupportLabel>{t('dialog.videoDifficulties')}</SupportLabel>
            )}
            </Box>
            <CloseButton onClick={handleClose} />
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '16px', marginTop: '0'}}>
            <GraySupport>{t('dialog.talkSupport')}</GraySupport>
          </Box>
          <ShareButton 
            color={loginButton ? loginButton.backgroundColor : "#0166FF"}
            fontColor={loginButton ? loginButton.textColor : "#fff"} 
            onClick={handleSupportMessage}
            >
            {t('buttons.sendSupport')}
          </ShareButton>
        </Box>
      </Box>
    );
  } else if (type === 'app') {

    dialogContent = (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center"  width="100%">
          <Box flexGrow={1} padding={'0' }>
            <RecordedLabel>
            {t('dialog.downloadApp')}
            </RecordedLabel>
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '32px'}}>
          <LightLabel>
          {t('dialog.downloadingApp')}
          </LightLabel>
        </Box>
        <Box style={{width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>        
          <ShareButton onClick={handleApp} 
            color={loginButton ? loginButton.backgroundColor : "#0166FF"}
            fontColor={loginButton ? loginButton.textColor : "#fff"} 
          >
            {t('buttons.goToApp')}
          </ShareButton>
        </Box>
      </Box>
    );
    
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          maxWidth: '100% !important',
          width: '100%',
          maxHeight: '270px',
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <DialogContent
        className={isMobile ? 'mobile-dialog-content' : 'desktop-dialog-content'}
        style={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
}
