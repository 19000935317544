import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import logger from '../../utils/logger'
import storage from '../../lib/storage'
import { FakePlayer } from './fake-player'

import { loginWithHash, getPatientExam, getIntroUrl, getTsFromM3U8Link, getTSFileFromLink, getPatient } from '../../lib/api'
import { Waiting } from './waiting'
/**
 * @todo Alterar o local da função, para uma pasta "shared"
 */
import { examFactory, getStreamURL } from '../../utils/functions'
import { Error } from './error'
import { useParams } from 'react-router-dom'
import Player from '../../components/player'
import { tsUrl } from '../../utils/ts-url'

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;

    background-color: black;
`

const PLAYER_STATUS = {
    OK: 'OK',
    LOADING: 'LOADING',
    WAITING: 'WAITING'
}


const EmbedPlayer = () => {
    const [examStatus, setExamStatus] = useState('LOADING')
    const [patient, setPatient] = useState()
    const { exam, hash, intern } = useParams()

    useEffect(() => {
        startingRequests()
    }, [])

    const startingRequests = async () => {

        try {
            getShareTokenFromApi()
            .then(() => getPatientFromApi())
            
        } catch (error) {
            console.log(error)
        }
    }

    const getShareTokenFromApi = async () => {
        try {
            const { data } = await loginWithHash({exam: exam, hash: hash})
            storage.saveSession(data)

        } catch (error) {
            console.log('login error');
        }
    }

    const getPatientFromApi = async () => {
        
        try {
            const { payment, data }  = await getPatient()

            if(payment) {

                redirect(payment)
            } else {

                const patientFactory = examFactory(data)
                const { showIntro, examStatus, introURL, m3u8, mp4Url } = patientFactory

                const responseM3U8 = await getTsFromM3U8Link(m3u8)
                
                if(responseM3U8) { 
                    const responseTS = await getTSFileFromLink(tsUrl(responseM3U8))

                    if(responseTS === 200) {
        
                      setPatient({
                        intro: introURL ? introURL : "",
                        src: m3u8,
                        type: 'application/x-mpegURL'
                      })
                    } else {
        
                      setPatient({
                        intro: introURL ? introURL : "",
                        src: mp4Url,
                        type: 'video/mp4'
                      })
                    }
                } else {
                    setPatient({
                        intro: introURL ? introURL : "",
                        src: mp4Url,
                        type: 'video/mp4'
                      })
                }
            }
        } catch (error) {
            console.log('embed player error', error)
        }
    }

    return (
        <StyledContainer>
            {patient ? <Player
                options={{
                    sources: [
                        { src: patient.intro, type: 'video/mp4' },
                        { src: patient.src, type: patient.type }
                    ]
                }}
            /> : <p>Carregando...</p>} 
        </StyledContainer>
    )
}

export default EmbedPlayer