import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';

const StyledReactPhoneInput = styled(ReactPhoneInput)`
  .special-label {
    display: none;
  }
  .selected-flag {
    position: relative;
    top: -4px;
  }
  .dropdown-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    @media screen and (min-width: 486px) {
      width: 50%;
    }
  }

  .country-list > .search{
    margin: 0;
    padding: 8px !important;
  }

`;

const useStyles = makeStyles(() => ({
  phoneInput: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
    width: '100%',
    height: '42px',
    border: '1px solid #DDE5EC',
    borderRadius: '8px',
    marginBottom: '10px',
    justifyContent: 'center',
    paddingLeft: '50px',
    letterSpacing: '1px',
    transition: 'border-color 0.3s ease',
    color: '#020523',
    '&:placeholder':{
      color: '#020523',
    },
    '&:hover': {
      border: '1px solid #B7C6D1',
    },
    '&:hover::placeholder': {
      color: '#8B98A3',
    },
    '&:active': {
      border: '1px solid #B7C6D1',
    },
    '&:focus': {
      outline: 'none',
      border: '1px solid #8B98A3',
      color: '#020523'
    },
    '&:not(:hover)::placeholder': {
      color: '#DDE5EC',
    },

    '.search': {
      margin: 0
    }
  },
}));


function PhoneComponent(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <StyledReactPhoneInput
      {...props}
        inputProps={{
          className: classes.phoneInput,  
        }}
        searchStyle={{
          width: '100%',
          boxSizing: 'border-box',
          height: '40px',
          border: '1px solid #DDE5EC',
          borderRadius: '8px',
          fontSize: '14px',
          color: '#333',
          backgroundColor: 'transparent',
          marginLeft: 0,
          
        }}
        dropdownStyle={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.8rem',
        }}
        placeholder=''
        defaultCountry='br'
        preferredCountries={['br', 'us', 'ar', 'mx', 'mz', 'uy' ]}
        enableSearch={true}
        searchPlaceholder={t('login.phoneComponent.searchPlaceholder')}
        searchNotFound={t('login.phoneComponent.searchNotFound')}
        copyNumbersOnly={true}
        specialLabel={false}
        disableInitialCountryGuess={false}
        countryCodeEditable={false}
    />
  );
}

export default PhoneComponent;
