import React, { useContext, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PatientContext } from '../../../../contexts';

export default function AcceptTermsOfUse({ label, onAcceptChange }) {
  const [checked, setChecked] = useState(false);
  const { settings } = useContext(PatientContext)
  const { loginButton } = settings

const getLink = (termType) => {
  if (termType === 'termsLink') {
    return 'https://documentos.vlabhealth.com/TERMOS-DE-USO-V-BABY.pdf';
  } else if (termType === 'termsPolitic') {
    return 'https://documentos.vlabhealth.com/POLITICADEPRIVACIDADE.pdf';
  } else if (termType === 'termsHealth') {
    return 'https://documentos.vlabhealth.com/TermoDeColetaDeDadosDeSaúde.pdf';
  } 
};

const renderColoredLabel = () => {
  const elements = [];
  const termRegex = /\[(\w+)\]([^\[]+)\[\/\1\]/gi;
  let match;
  let lastIndex = 0;

  while ((match = termRegex.exec(label)) !== null) {
    const [fullMatch, termType, termText] = match;
    const precedingText = label.substring(lastIndex, match.index);

    if (precedingText.length > 0) {
      elements.push(precedingText); 
    }

    let link = getLink(termType);

    elements.push(
      <a
        key={elements.length}
        href={link}
        rel="noreferrer noopener"
        target="_blank"
        style={{
          color: '#5E5E5E',
          fontWeight: 400,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {termText}
      </a>
    );

    lastIndex = match.index + fullMatch.length;
  }

  const remainingText = label.substring(lastIndex);
  if (remainingText.length > 0) {
    elements.push(remainingText);
  }

  return <div>{elements}</div>;
};

  
  
const handleCheckboxClick = () => {
  const newChecked = !checked;
  setChecked(newChecked);
  onAcceptChange(newChecked);
};

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckboxClick}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
          sx={{
            width: '10px',
            height: '10px',
            '&.MuiButtonBase-root.MuiCheckbox-root': {
              color: '#DDE5EC',
              marginRight: 0,            
              '&.Mui-checked': {
                color: loginButton ? loginButton.backgroundColor : '#0166FF'
              },
            },
          }}
        />
      }
      label={
        <div
          style={{
            position: 'relative',
          }}
        >
          {renderColoredLabel()} 
        </div>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
        '&.MuiFormControlLabel-root': {
          marginRight: 0,
          marginLeft: 0,
          padding: '4px 4px 4px 4px',
        },
        '.MuiTypography-root': {
          color: 'rgb(139, 152, 163)',
          fontWeight: 400,
          fontSize: '10px',  
          marginLeft: '8px',
          whiteSpace: 'pre-wrap',
        },
      }}
    />
  );
}  