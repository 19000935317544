import { createContext, useContext, useState } from "react";

const ExamContext = createContext(null)

function ExamProvider({children}) {
    const [exam, setExam] = useState(null)

    return(
        <ExamContext.Provider value={[exam, setExam]}>
            {children}
        </ExamContext.Provider>
    )
}

const useExam = () => {
    const context = useContext(ExamContext)

    if (!context) {
        throw new Error("useExam must be used within an ExamContext")
    }

    return context
}

export {
    ExamProvider,
    useExam
}