import React, { useEffect } from 'react'
import ComponentLogin from './components/component-login'
import { useParams } from 'react-router-dom';
import storage from '../../lib/storage';

const Login = () => {
  const { id } = useParams()

  useEffect(() => {

    storage.saveOrganization(id)
  }, [])

    return (
        <ComponentLogin/>
      );
  };
    
export default Login