import * as React from 'react';
import styled, {css} from 'styled-components';
import { isMobileDevice, landscape } from '../../utils/mobile-detect';
import { Skeleton } from '@mui/material';

const Container = styled(Skeleton)`
    
    background-color: black;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 28.125% 0; /* 16:9 proporção (9 / 16 * 100%) */
    height: 0;
    overflow: hidden;
    width: 40rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: white;
    text-align: center;

    h2 {
        font-size: 0.975rem;
    }

    p {
        font-size: 0.725rem;
    }

    ${(props) => props.isMobileLandscap && css`
        
        width: 32rem;
    `}

    @media (min-width: 1440px) { 
      width: 68rem;
    }

    @media (max-width: 767px) { 
      width: 100%;
      border-radius: 0;
    }
`

export function SkeletonPlayer() {
    const isMobileLandscap = landscape()

    return (
        <Container variant="rectangular" isMobileLandscap={isMobileLandscap} />
    );
}