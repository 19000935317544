import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Share from '../../../assets/share_icon.svg';
import WhatsappIcon from '../../../assets/Whatsapp.svg'
import LinkIcon from '../../../assets/Link.svg';
import { useMediaQuery } from 'react-responsive';
import { generateShareLink } from '../../../utils/generate-share-link';
import { useSnackBar } from '../../../contexts/snackbar';
import { PatientContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { STATUS_LIVE } from '../../../utils/constants';
import { useMixpanel } from 'react-mixpanel-browser';

const IconButton = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: '#51525F';
`;

const ShareButton = styled(Button)`
  && {
    ${props => css`
        background-color: ${props.colorbackground};
        color: ${props.colorfont};
    `}
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    margin-right: 0;   
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    border: 1px solid transparent;
    text-transform: none;
    font-size: 13.3px;
    opacity: 1;
    transition: opacity 1s ease 0s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
      background-color: ${props => props.colorbackground};
      color: ${props => props.colorfont};
    }
    &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  && {
    .MuiMenu-paper {
      width: 300px;
      border-radius: 8px;
      margin-top: 8px;
      background-color: transparent; 
      box-shadow: none;
      &:hover {
        background: #F7F8FC;
        cursor: pointer;
      }
    }
    .MuiList-root {
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    width: 100%;    
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    color: '#51525F';
    font-size: 13.3px; 
    height: 42px;
    border: 1px solid #DDE5EC;
    border-top-left-radius: ${({ isFirst }) => (isFirst ? '8px' : '0')}; 
    border-top-right-radius: ${({ isFirst }) => (isFirst ? '8px' : '0')}; 
    border-bottom-left-radius: ${({ isLast }) => (isLast ? '8px' : '0')}; 
    border-bottom-right-radius: ${({ isLast }) => (isLast ? '8px' : '0')};
 }
`;

export default function ShareMenu({ exam, hash, examType, disabled, globalPhone }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { showSnackBar } = useSnackBar()
  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const { t, i18n } = useTranslation();
  const { settings } = useContext(PatientContext)
  const { loginButton } = settings
  const mixpanel = useMixpanel()

  const handleCopyLink = async () => {
    const textToCopy = generateShareLink(exam, hash);

    try{
      await navigator.clipboard.writeText(textToCopy);
      showSnackBar(t('alertMessages.copiedLink'), 'success')
      mixpanel.track('WEBAPP_CLICK_ON_SHARE', { 
        distinct_id: globalPhone,
        'SHARED_OCURRED': true,
        'TYPE_OF_DEVICE': 'Desktop',
        'URL_EXAM': textToCopy,
        'MESSAGE_SHARED': 'Link copiado para área de transferência, compartilhamento nativo não suportado.',
      });
    }catch (error) {
      console.log(error)
      showSnackBar(t('alertMessages.errorLink'), 'error')
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (event) => {
    const textToCopy = generateShareLink(exam, hash);
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(textToCopy)}`;

    if(!isMobile) {
      setAnchorEl(true);
      return
    }
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
        if (navigator.share && navigator.canShare) {
          const data = {
            title: t('shareMessages.title'),
            text: examType === STATUS_LIVE ? t('shareMessages.textLive') : t('shareMessages.textRecorded'),
            url: textToCopy,
          };
          const onSuccess = function () {
            showSnackBar(t('alertMessages.shareSuccess'), 'success');
            mixpanel.track('WEBAPP_CLICK_ON_SHARE', { 
              distinct_id: globalPhone,
              'SHARED_OCURRED': true,
              'TYPE_OF_DEVICE': 'Mobile',
              'URL_EXAM': textToCopy,
              'URL_SHARED': whatsappUrl,
              'MESSAGE_SHARED': 'Compartilhamento nativo suportado, o link foi compartilhado com sucesso.',
            });
          };
          const onError = function (error) {
            console.error('Erro ao compartilhar:', error);
            window.open(whatsappUrl, '_blank');
            mixpanel.track('WEBAPP_CLICK_ON_SHARE', { 
              distinct_id: globalPhone,
              'SHARED_OCURRED': false,
              'TYPE_OF_DEVICE': 'Mobile',
              'URL_EXAM': textToCopy,
              'URL_SHARED': whatsappUrl,
              'MESSAGE_SHARED': 'Compartilhamento nativo suportado, mas ocorreu um erro ao compartilhar, link copiado para a área de transferência.',
            });
          };
          navigator.share(data)
            .then(onSuccess)
            .catch(onError);
        } else {
          setAnchorEl(true);
        }
      })
      .catch((error) => {
        console.error('Failed to copy text', error);
      });
    };

    const handleShareWhatsApp = () => {
      const textToCopy = generateShareLink(exam, hash);
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(textToCopy)}`;
      window.open(whatsappUrl, '_blank');
      mixpanel.track('WEBAPP_CLICK_ON_SHARE', { 
        distinct_id: globalPhone,
        'SHARED_OCURRED': true,
        'TYPE_OF_DEVICE': 'Desktop',
        'URL_EXAM': textToCopy,
        'URL_SHARED': whatsappUrl,
        'MESSAGE_SHARED': 'Redirecionado para o Whatsapp desktop/web',
      });
    };

  return (
    <> 
      <ShareButton
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleCopy}
        colorbackground={loginButton ? loginButton.backgroundColor : "#0166FF"}
        colorfont={loginButton ? loginButton.textColor : "#fff"}
        disabled={disabled}
      >
        <img 
          style={{
            padding: '0px 6px 0px 0px',
            opacity: disabled ? 0.2 : 1,
            pointerEvents: disabled ? 'none' : 'auto',
            padding: '0px 6px 0px 0px',
          }}
           src={Share} alt="Share Icon" />
        {t('buttons.share')}
      </ShareButton>
        <StyledMenu
          id="basic-menu"
          anchorEl={document.getElementById('basic-button')}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem className="gtm-btn" id="gtm-btn-link" isFirst onClick={handleCopyLink}>
          <IconButton src={LinkIcon} />
          {t('buttons.copyLink')}
        </StyledMenuItem>

        <StyledMenuItem className="gtm-btn" id="gtm-btn-whatsapp" isLast onClick={handleShareWhatsApp}>
          <IconButton src={WhatsappIcon} />
          {t('buttons.whatsapp')}
        </StyledMenuItem>
        </StyledMenu>
    </>
  );
}
