import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { format, parseISO } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom'
import { loginWithHash, getPatient, getIntroUrl, getTsFromM3U8Link, getTSFileFromLink } from '../../lib/api'
import storage from '../../lib/storage'
import { useMediaQuery } from 'react-responsive';
import Player from '../../components/player'
import { LightLabel, RecordedLabel } from '../login/components/labels/label-access';
import { STATUS_LIVE } from '../../utils/constants'
import { examFactory, getStatusLabel } from '../../utils/functions'
import { getS3LogoUrl } from '../../utils/s3';
import { API_URL, MUSIC_URL } from '../../fetcher/constants'
import { useTranslation } from 'react-i18next';
import { tsUrl } from '../../utils/ts-url';
import ar_icon from   '../../assets/flags/ar.webp'
import br_icon from   '../../assets/flags/br.webp'
import us_icon from   '../../assets/flags/us.webp'

const ContainerBackgroundLoading = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #B7C6D1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  max-width: 700px;

`;

const ContainerBackground = styled.div`
  height: 100vh;
  background-color: #FFFF;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`; 

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
`;

const LabelLoading = styled.p`
    width: 198px;
    height: 22px;
    font-style: normal;
    font-size: 20px;
    line-height: 21.76px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${(props) => props.isMobile && `
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  `}
  ${(props) => (props.isTablet || props.isDesktop) && `
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
  `}
`;

const ContainerLabel = styled.div`
  display: flex;
  width: 50%;
  align-items: end;

  ${(props) => props.isMobile && `
    justify-content: space-evenly;
    width: 100%;
  `}

  ${(props) => (props.isTablet || props.isDesktop) && `
    justify-content: flex-end;
    gap: 32px;
  `}
`;

const LabelExam = styled.p`
    color: '#020523';
    font-weight: 700;
    font-family: 'Poppins', sans-serif;    
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    display: flex;
    justify-content: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
`;

const ContainerLogo = styled.div `
  width: 100%;
  height: 54px;
  ${props => css`
        background-image: url(${props.url});
    `}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const FlagIcon = styled.div`
  height: 24px;
  width: 32px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  
  border-radius: 2px;
`;

const StyledSelectWrapper = styled.div`
  position: fixed;
  top: 20px; 
  right: 20px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; 
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DDE5EC;
  border-radius: 8px;
  padding: 0 8px;
  height: 42px; 
  cursor: pointer;
`;

const StyledOptionList = styled.ul`
  position: absolute;
  top: 100%;
  width: 42px;
  background-color: #fff;
  border: 1px solid #DDE5EC;
  border-radius: 8px 8px;
  padding: 0;
  margin: 0;
  margin: 4px;
`;

const StyledOption = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
  height: 24px;
  justify-content: center;
`;

const PlayerContainer = styled.div`

  position: relative;
  box-sizing: border-box;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 40rem;

`

  const ShareExam = () => {
    const languageFlags = {
      'es': ar_icon,
      'pt-BR': br_icon,
      'en-US': us_icon,
    };

    const navigate = useNavigate()
    const [patient, setPatient] = useState()
    const { exam, hash, intern } = useParams()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' }); 
    const playerRef = React.useRef();
    const { t, i18n } = useTranslation();
    const [setting, setSettings] = useState({logoUrl: ""});

    const [isOptionListOpen, setIsOptionListOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const selectedFlag = languageFlags[selectedLanguage];

    useEffect(() => {
        startingRequests()
        getLogoUrl()
    }, [])

    useEffect(() => {
      setSelectedLanguage(i18n.language); 
    }, []);

    const toLogin = () => {
      navigate('/');
    };

    const getLogoUrl = async () => {
      const logoUrl = await getS3LogoUrl()
      setSettings({logoUrl})
    }
    
    const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      setIsOptionListOpen(false);
    };

    const PlayerOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        liveui: true,
        autoplay: true,
        liveui: false,
        controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
            muteToggle: false,
        },  
    };
   
    const startingRequests = async () => {

        try {
            getShareTokenFromApi()
            .then(() => getPatientFromApi())
            
        } catch (error) {
            console.log(error)
        }
    }

    const getShareTokenFromApi = async () => {
        try {
            const { data } = await loginWithHash({exam: exam, hash: hash})
            storage.saveSession(data)

        } catch (error) {
            console.log('login error');
            toLogin()
        }
    }

    const getPatientFromApi = async () => {
        
      try {
        const { payment, data }  = await getPatient()
        
        if(payment) {

          redirect(payment)
        } 
        else {
          
          const patientFactory = examFactory(data)

          const { introURL, m3u8, mp4Url, date, examStatus, examId, cycle } = patientFactory

          var vmusicError, responseM3U8, m3u8Url

          try{
            const response = await fetch(`${API_URL}/patient/media/${examId}/${cycle}/vmusic-token`, {
              headers: {
                'Authorization': `Bearer ${storage.getToken()}`,
              }
            })
            const responseString = await response.text()
            m3u8Url = `${MUSIC_URL}/v1/vlab/m3u8/${examId}/${responseString}/vod.m3u8`
          } catch (e) {
            console.log(e)
            vmusicError = true
            m3u8Url = m3u8
          }
          
          responseM3U8 = await getTsFromM3U8Link(m3u8)
  
          if(responseM3U8) {
  
            var responseTS
            if (vmusicError){
              responseTS = await getTSFileFromLink(tsUrl(responseM3U8))
            }
  
            if(!vmusicError || responseTS === 200) {
              setPatient({
                intro: introURL ? introURL : "",
                src: m3u8Url,
                type: 'application/x-mpegURL',
                date: date,
                examStatus: examStatus
              })
            } else {
              if (mp4Url) {
                setPatient({
                  intro: introURL ? introURL : "",
                  src: mp4Url,
                  type: 'video/mp4',
                  date: date,
                  examStatus: examStatus
                }) 
              } else {
                setPatient({
                  intro: '',
                  src: '',
                  type: 'video/mp4',
                  date: date,
                  examStatus: examStatus
                }) 
              }
            }
          } else {
            if (mp4Url) {
              setPatient({
                intro: introURL ? introURL : "",
                src: mp4Url,
                type: 'video/mp4',
                date: date,
                examStatus: examStatus
              })
            } else {
              setPatient({
                intro: '',
                src: '',
                type: 'video/mp4',
                date: date,
                examStatus: examStatus
              }) 
            }
          }
        }
      }  
      catch (error) {
        if (error.response && error.response.status === 401) {
          toLogin()
        }
      }
    }
  

    const formatDate = (date, language) => {
      const parsedDate = parseISO(date);
      const formatPattern = language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    
      return format(parsedDate, formatPattern);
    };
    
    const formatTime = (date, language) => {
      const parsedDate = parseISO(date);
      const timeFormat = language === 'en-US' ? 'h:mm a' : 'HH:mm';
    
      return format(parsedDate, timeFormat);
    };

    return(
        patient ?
        <>
          <StyledSelectWrapper>
            <StyledSelect onClick={() => setIsOptionListOpen(!isOptionListOpen)}>
            <FlagIcon style={{ backgroundImage: `url(${selectedFlag})` }} title={t('buttons.selectFlag')} />
            </StyledSelect>
            <StyledOptionList style={isOptionListOpen ? { visibility: 'visible'} : {visibility: 'hidden'}}>
              <StyledOption onClick={() => handleLanguageChange('es')}>
                <FlagIcon style={{ backgroundImage: `url(${ar_icon})` }} />
              </StyledOption>
              <StyledOption onClick={() => handleLanguageChange('pt-BR')}>
                <FlagIcon style={{ backgroundImage: `url(${br_icon})` }} />
              </StyledOption>
              <StyledOption onClick={() => handleLanguageChange('en-US')}>
                <FlagIcon style={{ backgroundImage: `url(${us_icon})` }} />
              </StyledOption>
            </StyledOptionList>
          </StyledSelectWrapper>

          <ContainerBackground isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
            <ContainerForm>
              <ContainerLogo url={setting.logoUrl}></ContainerLogo>
                <Container isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
                  <LabelExam>{t('watch.examDay', { date: formatDate(patient.date, selectedLanguage) })}</LabelExam>
                    <ContainerLabel isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
                    <LightLabel>{t('watch.realized', { date: formatTime(patient.date, selectedLanguage) })}</LightLabel>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                          {patient.examStatus === STATUS_LIVE && <div style={{ width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'red' }}></div>}
                          <LightLabel>{getStatusLabel(patient.examStatus, i18n)}</LightLabel>
                      </div>
                    </ContainerLabel>
                </Container>
                <PlayerContainer>
                  <Player
                    options={{
                      sources: [
                        { src: patient.intro, type: 'video/mp4' },
                        { src: patient.src, type: patient.type }
                      ]
                    }}
                  />
                </PlayerContainer>
            </ContainerForm>
          </ContainerBackground>
      </>
         : (
                <ContainerBackgroundLoading>
                    <ContainerCenter>
                        <LabelLoading style={{color: '#ECF2F8', fontWeight: 800}}>{t('loadingExam')}</LabelLoading>
                    </ContainerCenter>
                </ContainerBackgroundLoading>       
            )
    )
}

export default ShareExam;