import { useEffect, useState } from "react";
import { getPatient, getPatientDemo } from "../lib/api";
import { examFactory } from "../utils/functions";
import { useExam } from "../contexts/examContext";
import { useParams } from "react-router-dom";

export function useFetchExam() {
    const [isFetching, setIsFetching] = useState(true)
    const [exam, setExam] = useExam()
    const [error, setError] = useState(null)
    const { id } = useParams();

    useEffect(() => {
        if(id === 'lp-demo') {
            getPatientDemo().then(response => setExam(examFactory(response.data)))
            .catch(reason => setError(reason))
            .finally(() => setIsFetching(false))
        } else {
            getPatient()
            .then(response => setExam(examFactory(response.data)))
            .catch(reason => setError(reason))
            .finally(() => setIsFetching(false))
        }
    },[])

    return { isFetching, error }
}