import styled, { css }  from "styled-components";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${(props) => props.useBackgroundImage ? 
        css`

            background-image: url(${props.backgroundImageUrl});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        `
        :
        css`

            background: linear-gradient(${props.backgroundColor});
        `
    }
    

    ${(props) => (props.isMobile && !props.isLandscape) && css`

        justify-content: space-between;
    `}
`;

const ContainerVideo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) => (props.isMobile && !props.isLandscape) && css`
        width: 100%;

        display: flex;
        justify-content: center;

        padding: 0;
    `}
`;

const Header = styled.header`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;

    align-items: start;
    justify-content: space-between;

    ${(props) => (props.isMobile && !props.isLandscape) && css`

        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: #FFF;

        h2 {
            display: none;
        }
    `}
`

const Footer = styled.div`
    width: 100%;
    height: 2.5rem;
    
    box-sizing: border-box;
    padding: 6px 8px 0 8px;
    display: flex;

    justify-content: space-between;
    align-items: center;

    background-color: #FFF;
    box-shadow: 0px 0.001rem 5px 1px #E3E3E3; 
    
    @media (min-width: 767px) {

        display: none;
    }

    @media (orientation: landscape) {

        display: none;
    }

    p {
        color: var(--system-black-light, #484848);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
    }
`

const Logo = styled.div`
    width: 138px;
    height: 71px;
    ${props => css`
        background-image: url(${props.url});
    `}
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (min-width: 812px) {
        width: 138px;
        height: 71px;
    }

    @media screen and (max-width: 914px) and (orientation: landscape) {
        width: 68px;
        height: 36px
    }
`;

const Subtitle = styled.h2`
    color: ${(props) => props.textColorLive};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;

    margin-right: 33px;
    @media screen and (max-width: 914px) and (orientation: landscape) {

        font-size: 12px;
    }
`

const PlayerContainer = styled.div`

    position: relative;
    box-sizing: border-box;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 40rem;
  
    video {

        border-radius: 12px;
    }

    .container {
        
        border-radius: 12px !important;
    }

    .media-control-background {

        background: transparent !important;

    }

    ${(props) => (props.isMobile && !props.isLandscape) && css`

        width: 100%;

        video {

            border-radius: 0;
        }

        .container {

            border-radius: 0 !important;
        }
    `}

    ${(props) => props.isMobileLandscap && css`
        
        width: 32rem;
    `}

    @media (min-width: 1440px) { 
      width: 68rem;
    }
`

const StyledSelectWrapper = styled.div`
  position: fixed;
  top: 20px; 
  right: 20px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; 
`;

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;

  transition: all 0.9s;
  :hover {

    background-color: rgba(255, 255, 255, 0.3);
  }

  border-radius: 8px;
  padding: 0 8px;
  height: 42px; 
  cursor: pointer;
`;

const FlagIcon = styled.div`
  height: 24px;
  width: 32px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  border-radius: 2px;
`;

const StyledOptionList = styled.ul`
  position: absolute;
  top: 100%;
  width: 42px;
  background-color: #fff;
  border: 1px solid #DDE5EC;
  border-radius: 8px 8px;
  padding: 0;
  margin: 4px;
`;

const StyledOption = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
  height: 24px;
  justify-content: center;
`;

const SelectLanguageContainer = styled.div`
    margin-top: 10px;
    background-color: white;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    color: #5E5E5E;
    width: 18rem;
`

export {
    Container,
    ContainerVideo,
    Header,
    Footer,
    Logo,
    Subtitle,
    PlayerContainer,
    StyledSelectWrapper,
    StyledSelect,
    FlagIcon,
    StyledOptionList,
    StyledOption,
    SelectLanguageContainer
}