import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Checkout } from "./Checkout";

export default function CheckoutWrapper() {
  const isProd = process.env.REACT_APP_ENV === "production";
  const stripePromise = loadStripe(
    isProd
      ? "pk_live_Gq5azMUpxnzAiRUEzk7lQsCz000crfRftf"
      : "pk_test_6NQsDFKMnhHwvdBlvDCnJnio00qH3BKYRK"
  );

  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
}
