import React from 'react'
import { useNavigate } from 'react-router-dom'
import { withState } from 'recompose'

const Redirect = withState('path', 'setPath', null)(
    ({ path, setPath, children }) => {
        const redirect = (path, time) => {
            if(time){
                setTimeout(() => { setPath(path) }, time)
                return
            }

            setPath(path)
        }

        const navigate = useNavigate()

        if (path) navigate(path)

        return children({ redirect })
    }
)

// eslint-disable-next-line react/display-name
export const withRedirect = Component => props => (
    <Redirect>
        {({ redirect, ...rest }) => 
            <Component 
                {...rest} 
                {...props} 
                redirectTo={redirect} 
            />}
    </Redirect>
)
