import React, { useEffect } from 'react'
import { withRedirect } from '../hoc/with-redirect'
import { subscribe } from 'pubsub-js'
import { REDIRECT_FROM_FETCHER } from '.'

const FetcherRedirect = ({ redirectTo }) => {

    useEffect(() => {
        subscribe(REDIRECT_FROM_FETCHER, () => {
            redirectTo('/')
        })
    }, [])

    return <div />
}

export default withRedirect(FetcherRedirect)
