import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { resendSms } from '../../../../lib/api';
import { useSnackBar } from '../../../../contexts/snackbar';
import PhoneComponent from '../component-login/PhoneComponent';
import BoxContainer from '../box-container';
import { BoldLabel, TextLabel, GrayLabel, LightLabel } from '../labels/label-access';
import { CustomButton, GrayButton, SubmitButton, ResendButton } from '../buttons';
import { PatientContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

const ContainerButton = styled.div`
  justify-content: space-between;
  display: flex;
  padding-top: 14px;
`;

const Flexform = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 100%;
  @media screen and (min-width: 486px) {
      width: 100%;
      margin: 24px 24px;
    } 
`;


const FormForgotten = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 100%;
  justify-content: flex-start;
  @media screen and (max-width: 486px) {
      width: 100%;
      margin: 24px 24px;
    } 
`;

const RecoveryAccess = ({ onBackClick}) => {
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const { settings } = useContext(PatientContext)
  const { loginButton, country } = settings
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const mixpanel = useMixpanel()

  const { t, i18n} = useTranslation()

  const toLogin = () => {
    navigate('/');
  };
  
  const handleError = async (message) => {
    showSnackBar(message, 'error')
  }

  const handleSubmit = async (values) => {
    setIsLoading(true) 
    if(!values.identifier || values.identifier.length <= 4) {
      handleError(t('alertMessages.validNumber'))
      return
    }

    try {
        const { data } = await resendSms({'identifier': values.identifier})
        setIsSubmitted(true);
        mixpanel.track('RESEND_ACCESS_CODE', { 
          distinct_id: values.identifier,
          'RESEND_SMS': true,
        });
    } catch (error) {
        console.log(error)
        handleError(t('alertMessages.sendError'))
        mixpanel.track('RESEND_ACCESS_CODE', { 
          distinct_id: values.identifier,
          'RESEND_SMS': false,
        });

    } finally {
      setIsLoading(false)    
    }
  }

  const validadeIdentifier = Yup.object().shape({
    identifier: Yup.string().required((value) => {
      handleError(t('alertMessages.validNumber'))
    })
  });

  return (
    <Formik
      initialValues={{'identifier': ''}}
      onSubmit={handleSubmit}
      validationSchema={validadeIdentifier}
    >
      {(props) =>
        isSubmitted ? ( 
          <Forgotten
            onBackClick={onBackClick}
            phoneNumber={props.values.identifier}
            toLogin={toLogin} 
          />
        ) : (
          <BoxContainer>
            <BoldLabel>{t('login.forgotAcess')}</BoldLabel>
            <Flexform >
              
              <TextLabel>{t('login.phone')}</TextLabel>
            
              <PhoneComponent
                inputProps={{ name: 'identifier' }}
                onChange={props.handleChange('identifier')}
                country={country ? country.toLowerCase() : 'br'}

              />
              <ContainerButton>
                <GrayButton onBackClick={toLogin}>{t('buttons.back')}</GrayButton>
                <SubmitButton
                  color={loginButton ? loginButton.backgroundColor : "#0166FF"}
                  fontColor={loginButton ? loginButton.textColor : "#fff"}
                >
                  {t('buttons.recovery')}
                </SubmitButton>
              </ContainerButton>

            </Flexform>

      </BoxContainer>
        )
      }

    </Formik>
  );
};

export const Forgotten = ({ phoneNumber, toLogin }) => {

  const [smsSended, setSmsSended] = React.useState(false)
  const [canResend, setCanResend] = useState(false);
  const [countdown, setCountdown] = useState();
  const { settings } = useContext(PatientContext)
  const { loginButton } = settings
  const { t, i18n} = useTranslation()
  const mixpanel = useMixpanel()

  useEffect(() => {  
    const [secondsInterval, secondsTimeOut] = interval()

    return () => {
      clearTimeout(secondsInterval);
      clearTimeout(secondsTimeOut);
    };
  }, []);

  const interval = () => {
    setCountdown(30)
    const secondsInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    
    const secondsTimeOut = setTimeout(() => {
      clearInterval(secondsInterval)
    }, 30000)

    return [secondsInterval, secondsTimeOut]
  }

  
  const handleBackLogin = () => {
    toLogin();
  };

  const resendAccessCode = async () => {
      try {
          await resendSms({'identifier': phoneNumber })
          setSmsSended(true)
          setCanResend(false);
          mixpanel.track('RESEND_ACCESS_CODE', { 
            distinct_id: phoneNumber,
            'RESEND_SMS': true,
          });
      } catch (err) {
        console.log(err)
        mixpanel.track('RESEND_ACCESS_CODE', { 
          distinct_id: phoneNumber,
          'RESEND_SMS': false,
        });
      } 
  }

  useEffect(() => {
    setCanResend(!smsSended);
  }, [smsSended]);

  const handleResendClick = async () => {
    interval(); 
    await resendAccessCode();
  };

  return (
    
    <BoxContainer>
      <BoldLabel>{t('recovery.recoveringCode')}</BoldLabel>
      <GrayLabel>{t('recovery.smsCode')}</GrayLabel>
      <LightLabel>{t('recovery.didntArrive', { countdown })}</LightLabel>      
      <FormForgotten>
        <ContainerButton>
          <ResendButton disabled={countdown !== 0} onClick={handleResendClick}>{t('buttons.resend')}</ResendButton>
          <CustomButton 
            onBackClick={handleBackLogin}
            color={loginButton ? loginButton.backgroundColor : "#0166FF"}
            fontColor={loginButton ? loginButton.textColor : "#fff"}
          >
            {t('buttons.understood')}
          </CustomButton>
        </ContainerButton>
      </FormForgotten>
    </BoxContainer>
  );
}

export default RecoveryAccess;
