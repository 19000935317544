import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import JsBarcode from "jsbarcode";
import { FiHelpCircle, FiClipboard } from "react-icons/fi";

import { formatPrice } from "../../utils/formatPrice";
import { formatTime } from "../../utils/formatTime";
import { getDate } from "../../utils/getDate";
import { SubmitButton } from "../login/components/buttons";
import { QRCodeCanvas } from "qrcode.react";
// import Button from "../../components/Button";
import Input from "../../components/Input";
import { ModalInfoPix } from "./components/ModalInfoPix";
// import { useApi } from "../../hooks/useApi";
// import { useQuery } from "react-query";
import { FaRegCheckCircle } from "react-icons/fa";
// import Loading from "../../components/Layout/components/Loading";
import FlexContainer from "../../components/Flex";
import { paymentStatus } from "../../lib/api";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  background: #f7f3f2;
  padding: 24px;
  height: 100%;
  * {
    &::selection {
      background: #5d4deb;
      color: white;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const Section = styled.section`
  border-radius: 10px;
  background: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 40%;

  svg {
    width: 100%;
  }

  p {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    color: #484848;
  }

  span {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: calc(100% - 48px);
    padding: 24px;
  }
`;

const StyledInput = styled(Input)`
  background: white;
  border: 1px solid #d2d2d2;
  color: black;
  overflow-x: scroll;
  text-align: center;
`;

const ProgressBar = styled.div`
  height: 4px;
  width: 100%;
  background-color: #f7f3f2;
  border-radius: 10px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    height: 4px;
    width: ${({ progress }) => `${progress * 100}%`};
    background-color: #5d4deb;
    border-radius: 10px;
    transition: width 0.3s ease;
  }
`;

const InfoIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
  height: 24px;
  position: fixed;
  right: 24px;
  bottom: 24px;
`;

const MAX_TIME_TO_PAY = 3 * 60 * 1000;

export const FinishPurchase = () => {
  const location = useLocation();
  const rechargeData = location.state;
  const [isExpired, setIsExpired] = useState(false);
  const [timeToPay, setTimeToPay] = useState(MAX_TIME_TO_PAY);
  const [status, setStatus] = useState(-1);
  const interval = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      status <= 3 &&
      rechargeData &&
      (rechargeData.payment_method === "pix" ||
        rechargeData.payment_method === "card")
    ) {
      const intervalId = setInterval(async () => {
        if (isExpired) {
          clearInterval(intervalId);
          return;
        }

        const response = await paymentStatus();
        setStatus(response.data.code);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [rechargeData, isExpired]);

  useEffect(() => {
    if (status === 4) {
      setTimeout(() => {
        navigate("/loading");
      }, 1000);
    }
  }, [status]);

  useEffect(() => {
    getPurchaseData();

    setTimeToPay(getTimeToPay());

    interval.current = setInterval(() => {
      setTimeToPay((prev) => {
        if (prev > 0) {
          return prev - 1000;
        } else {
          handleTimeout();
          return 0;
        }
      });
    }, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [rechargeData]);

  function getTimeToPay() {
    const expirationDate = rechargeData?.pix?.expiration_date_qrcode;
    const expire_at = new Date(expirationDate || "");
    const timeToPay = expire_at.getTime() - new Date().getTime();
    return timeToPay;
  }

  const getPurchaseData = async () => {
    if (!rechargeData) return;
    try {
      if (rechargeData.payment_method === "boleto") {
        JsBarcode("#barcode", rechargeData.number, {
          format: "CODE128",
          margin: 1,
          lineColor: "#000",
          width: 1.3,
          height: 40,
          displayValue: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTimeout = () => {
    setIsExpired(true);
  };

  const handleCopy = (text) => {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      showSnackBar("Código copiado", "success");
    } catch (error) {
      console.log(error);
    }
  };

  const buildPaymentCard = () => {
    switch (rechargeData?.payment_method) {
      case "pix":
        return (
          <>
            <p style={{ color: "#212121" }}>{t("payment.code")} Pix</p>
            <p>{t("payment.copyCode")}</p>

            {rechargeData?.pix?.qr_code && (
              <QRCodeCanvas value={rechargeData?.pix?.qr_code} size={300} />
            )}
            <StyledInput readOnly value={rechargeData?.pix?.qr_code} />

            <SubmitButton
              color="#624DEB"
              fontColor={"white"}
              onClick={() => handleCopy(rechargeData?.pix?.qr_code)}
              style={{ paddingLeft: "56px", paddingRight: "56px" }}
            >
              {t("payment.copyNumber")}
            </SubmitButton>
            <p>
              {t("payment.timeToExpire")} <b>{formatTime(timeToPay)}</b>
            </p>
            <ProgressBar progress={timeToPay / MAX_TIME_TO_PAY} />
            <p>{t("payment.timeDescription")}</p>
          </>
        );
      case "boleto":
        return (
          <>
            <p>
              {t("payment.expireIn")} {getDate(rechargeData.expires_at)}
            </p>
            <svg id="barcode"></svg>
            <p style={{ color: "#212121", fontWeight: 600, fontSize: "18px" }}>
              {t("payment.boletoNumber")}
            </p>
            <StyledInput readOnly value={rechargeData.number} />
            <FlexContainer gap="16px" flexWrap="wrap" justifyContent="center">
              <SubmitButton
                color="#624DEB"
                fontColor={"white"}
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = rechargeData.pdf;
                  link.setAttribute("download", "boleto.pdf");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                style={{ width: "150px" }}
              >
                {t("payment.downloadPdf")}
              </SubmitButton>
              <SubmitButton
                color="#624DEB"
                fontColor={"white"}
                onClick={() => handleCopy(rechargeData.number)}
                style={{ width: "150px" }}
              >
                {t("payment.copyNumber")}
              </SubmitButton>
            </FlexContainer>
            {/* <div
              style={{
                display: "flex",
                gap: "4px",
                cursor: "pointer",
                width: "100%",
                justifyContent: "center",
              }}
              onClick={() => handleCopy(window.location.href)}
            >
              <FiClipboard color="#484848" style={{ width: "fit-content" }} />
              <p>Copiar link para compartilhar</p>
            </div> */}
          </>
        );
      default:
        return <></>;
    }
  };
  if (!rechargeData) return null;

  return (
    <>
      <div style={{ height: "100vh", overflow: "auto" }}>
        <Container>
          {/* <ModalInfoPix
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
          /> */}
          {isExpired && rechargeData.payment_method === "pix" ? (
            <Section>
              <strong>{t("payment.expiredPayment")}</strong>
            </Section>
          ) : (
            <>
              <Section>
                <h3 style={{ margin: 0 }}>{t("payment.buyRequest")}</h3>
                {/* <p style={{ color: "#5D4DEB" }}>
                  Pedido {rechargeData.id || rechargeData?.payment_id}
                </p> */}
                <p>
                  {t("payment.receiveBuy")}{" "}
                  {rechargeData?.title && (
                    <>
                      {" "}
                      {t("payment.in")} <b>{rechargeData?.title}</b>
                    </>
                  )}
                  . {t("payment.processing")}
                </p>
              </Section>

              {status === 4 && (
                <Section>
                  <h3 style={{ margin: 0, textAlign: "center" }}>
                    {t("payment.buySuccess")}
                  </h3>

                  <FaRegCheckCircle
                    color="#04a11e"
                    size="200px"
                    style={{ marginTop: "30px" }}
                  />
                </Section>
              )}

              {status >= -1 && status < 4 && (
                <Section>
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <span>Total:</span>
                    <span style={{ fontSize: "32px", color: "#5D4DEB" }}>
                      {formatPrice(rechargeData.value)}
                    </span>
                  </div>
                  {buildPaymentCard()}
                </Section>
              )}
            </>
          )}

          {/* {rechargeData.payment_method === "pix" && (
            <InfoIcon>
              <FiHelpCircle
                size={24}
                color="#5D4DEB"
                onClick={() => setModalOpen(true)}
              />
            </InfoIcon>
          )} */}
        </Container>
      </div>
    </>
  );
};
