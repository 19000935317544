export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)
    country: "Brasil",
    login: {
      phone: "Celular",
      accessCode: "Código de acesso",
      forgotAcess: "Esqueci meu código de acesso",
      phoneComponent: {
        searchPlaceholder: "Pesquisar o país",
        searchNotFound: "Nenhum país correspondente",
      },
    },
    recovery: {
      recoveringCode: "Recuperando código",
      smsCode:
        "Você vai receber o código de acesso do seu último exame por WhatsApp em breve",
      didntArrive:
        "Não chegou? Você pode pedir para enviarmos novamente em {{countdown}} segundos",
    },
    notFound: {
      notPage: "Não encontramos essa página",
      openExam:
        "Você pode abrir o exame com o número de telefone e código de acesso ou pedindo um novo link pra quem te enviou.",
      goToLogin: " Ir para o login",
    },
    buttons: {
      enter: "Entrar",
      back: "Voltar",
      recovery: "Recuperar",
      resend: "Reenviar",
      understood: "Entendi",
      download: "Baixar",
      share: "Compartilhar",
      copyLink: "Copiar link",
      whatsapp: "Whatsapp",
      phone: "Telefone",
      online: "Online",
      schedule: "Agendar",
      help: "Precisa de ajuda? Fale com a gente",
      sendFeedback: "Enviar feedback",
      sendSupport: "Pedir ajuda ao suporte",
      goToApp: "IR PARA O APP",
      selectFlag: "Selecione o seu idioma",
      jump: "Pular",
    },
    loading: "Carregando...",
    loadingExam: "Carregando exame",
    watch: {
      examDay: "Exame do dia {{date}}",
      realized: "Realizado às {{date}}",
      statusRecorded: "Gravado",
      statusLive: "Ao vivo",
      statusSoon: "Em breve",
    },
    dialog: {
      experience: "Como está sendo sua experiência?",
      leaveComment: "Deixe um comentário sobre a sua experiência",
      videoDifficulties:
        "Ops! Detectamos um possível problema na reprodução do seu exame.",
      talkSupport:
        "Para uma solução rápida, entre em contato com nosso suporte agora mesmo. Estamos aqui para ajudar!",
      downloadApp: "Baixe o V-Baby",
      downloadingApp:
        "Fazendo o download do app V-Baby a sua experiência fica mais completa! Assista aos exames, acompanhe o crescimento do seu bebê e muito mais!",
      errorDownload:
        "Ops! Detectamos um possível problema ao baixar seu exame.",
      schedule: "Agendar exame",
    },
    shareMessages: {
      title: "Meu ultrassom",
      textLive:
        "Oi pessoal! 🌟 \nMeu ultrassom está acontecendo agora! Venham assistir ao vivo através deste link: \n",
      textRecorded:
        "Olá! \nA gravação do ultrassom do nosso bebê está disponível. 🌟 \n",
    },
    supportMessages: {
      messageViewUser:
        "Olá! \nEstou com dificuldades para visualizar meus exames no Assista. \nPreciso de ajuda para solucionar o problema.",
      messageDownloadUser:
        "Olá! \nEstou com dificuldades para baixar meus exames no Assista. \nPreciso de ajuda para solucionar o problema.",
      messageHelp:
        "Olá! \nEstou enfrentando dificuldades com a solução Assista. Poderiam ajudar?",
      askSupport: "Pedir ajuda ao suporte",
    },
    alertMessages: {
      errorData: "{{error}} Celular ou código de acesso incorretos.",
      requiredCode: "Código de acesso é obrigatório!",
      validNumber: "Digite um número de telefone válido.",
      sendError:
        "Houve um erro ao enviar seu código de acesso, tente novamente.",
      copiedLink: "Link copiado para a área de transferência",
      errorLink: "Falha ao copiar o link para a área de transferência",
      shareSuccess: "Compartilhamento bem-sucedido!",
      downloadingExam: "Baixando exame",
      babyDetails: "Carregando todos os detalhes do bebê...",
      preparingVideo: "Preparando o melhor vídeo...",
      concluded: "Concluído!",
      noContent:
        "Não é possível baixar o exame ainda, aguarde até o ao vivo ser finalizado",
      error: "Erro ao baixar exame",
      downloadError:
        "Um erro inesperado aconteceu, tente novamente mais tarde.",
      examLiveError: "Erro ao transmitir o exame",
    },
    acceptTerms: {
      privacyPolitics:
        "Li os [termsLink]Termos de Uso[/termsLink] e a [termsPolitic]Política de Privacidade[/termsPolitic].",
      healthPolitics:
        "Li, concordo e dou meu consentimento para o [termsHealth]Termo de Coleta de Dados de Saúde[/termsHealth].",
    },

    poweredBy: {
      titleVbaby: "Gravação de Ultrassom por",
      titleMedstreamer: "Gravação de Exame por",
    },

    changeLan: {
      label: "Trocar o Idioma:",
    },

    footer: {
      label: "Feito com 💜 por ",
    },

    endedExam: {
      title: "Essa transmissão ao vivo já encerrou!",
      description:
        "A gravação está disponível no app V-Baby, somente para o número informado na clínica.",
      descriptionTwo: "A gravação está disponível no Resultado de Exames - ",
    },

    terms: {
      privacyPolitics: "Políticas de Privacidade",
      termsOfUse: "Termos de Uso",
      healthTerms: "Termo de Coleta de Dados de Saúde",
    },

    endedExamDemo: {
      title: "Essa transmissão já encerrou!",
      description:
        "Converse com nossos especialistas e descubra como o V-Baby pode trazer mais praticidade e eficiência ao seu dia a dia.",
    },

    demo: {
      info: "A transmissão pode ter atraso de alguns segundos.",
    },
    payment: {
      boleto: "Boleto",
      creditCard: "Cartão de crédito",
      errorToPay:
        "Erro ao processar o pagamento, verifique os dados e tente novamente.",
      selectPayment: "Selecione a forma de pagamento",
      paymentDetails: "Dados para contato",
      name: "Nome completo",
      addressToCharge: "Endereço de cobrança",
      payment: "Pagamento",
      endBuy: "Finalizar compra",
      birthday: "Data de nascimento",
      city: "Cidade",
      state: "Estado",
      street: "Rua",
      fillFields: "Preencha todos os campos",
      next: "Próximo",
      copyCode:
        "Copie o código abaixo e utilize o Pix Copia e Cola no Aplicativoque você vai fazer o pagamento:",
      code: "Código",
      copyNumber: "Copiar número",
      timeToExpire: "O tempo para você pagar acabe em:",
      timeDescription:
        "Você tem até 3 minutos para fazer o pagamento. Após esse tempo, o pedido será cancelado.",
      expireIn: "Vence em",
      boletoNumber: "Número do boleto",
      downloadPdf: "Baixar PDF",
      expiredPayment: "Pagamento expirado",
      buyRequest: "Solicitação de compra",
      receiveBuy: "Recebemos sua solicitação de compra",
      in: "no",
      processing:
        "Estamos processando seu pedido e em breve entraremos em contato com mais informações. Obrigado pela preferência!",
      buySuccess: "Compra realizada com sucesso!",
    },
  },
};
