import React from 'react';
import styled from 'styled-components';

const Labelbold = styled.p`
    width: 179px;
    height: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13.06px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
    color: #020523; 
`;

const LabelGray = styled.p`
    height: 28px;
    color: #51525F;
    margin-top: 20px;
    text-align: center;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12.9px;
    line-height: 14px;
    letter-spacing: 0.5px;
`;

const LabelLight = styled.p`
    height: 28px;
    margin-top: 16px;
    color: #8B98A3;
    text-align: center;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12.9px;
    line-height: 14px;
    letter-spacing: 0.5px;
`;

const LabelText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #8b98a3;
  white-space: pre-wrap;
  align-items: center;
  margin-bottom: 10px;
`;

const LabelRecorded = styled.p`
    color: '#020523';
    font-weight: 700;
    height: 27px;
    font-family: 'Poppins', sans-serif;    
    font-style: normal;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
`;

const BoldLabel = ({children}) => {
    return (
         <Labelbold>{children}</Labelbold>
    );
}

const GrayLabel = ({children}) => {
  return (
        <LabelGray>{children}</LabelGray>

  );
}

const LightLabel = ({children}) => {
  return (
        <LabelLight>{children}</LabelLight>

  );
}

const TextLabel = ({children}) => {
    return (
          <LabelText>{children}</LabelText>
    );
  }


const RecordedLabel = ({children}) => {
  return (
        <LabelRecorded>{children}</LabelRecorded>
  );
}

export { BoldLabel, GrayLabel, LightLabel, TextLabel, RecordedLabel };
