const ENV = process.env.REACT_APP_ENV

const mapper = new Map([
    ['localhost', 'https://stg-api.vlab.live/v1'],
    ['staging', 'https://stg-api.vlab.live/v1'],
    ['production', 'https://api.vlab.live/v1'],
])

const musicMapper = new Map([
    ['localhost', 'https://stg-music.vlab.live'],
    ['staging', 'https://stg-music.vlab.live'],
    ['production', 'https://music-api.vlab.live']
  ])

export const API_URL = mapper.get(ENV)
export const MUSIC_URL = musicMapper.get(ENV)
