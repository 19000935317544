import React, { useContext, useEffect, useState } from 'react'
import { add, format, parseISO } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { getS3LogoUrl } from '../../utils/s3';
import { STATUS_LIVE } from '../../utils/constants';
import { getShareToken, getTSFileFromLink, getTsFromM3U8Link, getUnit, getUrlDownloadExam } from '../../lib/api'
import { useSnackBar } from '../../contexts/snackbar'
import { getStatusLabel, unitFactory } from '../../utils/functions'
import Player from '../../components/player'
import Download from '../../assets/baixar.svg'
import DialogComponent from './components/DialogComponent';
import ShareMenu from './components/ShareMenu';
import { useTranslation } from 'react-i18next';
import { tsUrl } from '../../utils/ts-url';
import { useNavigate } from 'react-router-dom';
import { getGlobalPhone } from '../../utils/functions';
import { useMixpanel } from 'react-mixpanel-browser';
import { Container, ContainerButton, ContainerDescription, ContainerLabel, ContainerLogo, Divider, Footer, Header, InfoLabel, LabelExam, Main, PlayerContainer, PoliciesContainer, ScheduleContainer, ScheduleContainerButton, ScheduleSection, SecondButton, SelectLanguageButtonContainer, SelectLanguageContainer, StyledFloat, TextButton, UnitDescription } from './style';
import { useExam } from '../../contexts/examContext';
import { PatientContext } from '../../contexts';
import { API_URL, MUSIC_URL } from '../../fetcher/constants'
import storage from '../../lib/storage'
import {ContainerBackgroundLoading, ContainerCenter, LabelLoading} from '../loading/style';
import {fetchWithTimeout} from '../../fetcher/fetcher-timeout';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import ScheduleChannelDialog from './components/ScheduleChannelDialog';
import calendarIcon from '../../assets/icon/calendar.svg';
import addressIcon from '../../assets/icon/address.svg';


const Medstreamer = () => {
    const globalPhone = getGlobalPhone();
    const { showSnackBar } = useSnackBar()
    const [patient, setPatient] = useState(null)
    const [shareLink, setShareLink] = useState({
        exam: '',
        hash: ''
    })
    const [open, setOpen] = React.useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [callSupport, setCallSupport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });   
    const [setting, setSetting] = useState({logoUrl: ""});
    const { t, i18n } = useTranslation();
    const [supportLabelError, setSupportLabelError] = useState('');
    const mixpanel = useMixpanel();
    const [exam, setExam] = useExam()
    const [units, setUnits] = useState([])
    const { settings } = useContext(PatientContext)
    const navigate = useNavigate()
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [unit, setUnit] = React.useState(null);

    useEffect(() => {
      document.title = `Medstreamer - Gravação do Ultrassom - ${settings.name}`;

      if(exam === null || exam.implementation !== 3) {
        toLoading()
      }

      setPatientFromExam()
      requestShareToken()
      getLogoUrl()
      getUnitInfo()

      return () => {
        setExam(null)
      }
    }, [])

    const getLogoUrl = async () => {
      const logoUrl = await getS3LogoUrl()
      setSetting({logoUrl})
    }

    const getUnitInfo = async () => {
      try {
        const { data } = await getUnit()
        if(Array.isArray(data)) {
          setUnits(data.map(unitFactory))
        }
      } catch (error) {
        console.log(error)
      }
    }

    const toLoading = () => {
      navigate('/loading');
    };

    const setPatientFromExam = async () => {
      try {

        const handleMp4Url = () => {
          if (mp4Url){
            setPatient({
              intro: introURL ? introURL : "",
              src: mp4Url,
              secondarySrcs: [m3u8],
              type: 'video/mp4',
              date: date,
              examStatus: examStatus,
              showRate: showRate
            })
          } else {
              setPatient({
                intro: '',
                src: '',
                type: 'video/mp4',
                date: date,
                examStatus: examStatus,
                showRate: showRate
              })
              setTimeout(() => {
                setCallSupport(true);
              }, 5000);
          }
        }

        if(!exam) {
          throw new Error('Exam is null')
        }
        
        const { introURL, m3u8, mp4Url, examStatus, date, showRate, examId, cycle } = exam

        var vmusicError, responseM3U8, m3u8Url

        try{
          const response = await fetch(`${API_URL}/patient/media/${examId}/${cycle}/vmusic-token`, {
            headers: {
              'Authorization': `Bearer ${storage.getToken()}`,
            }
          })
          const responseString = await response.text()
          m3u8Url = `${MUSIC_URL}/v1/vlab/m3u8/${examId}/${responseString}/vod.m3u8`
        } catch (e) {
          console.log( e)
          vmusicError = true
          m3u8Url = m3u8
        }
        
        responseM3U8 = await getTsFromM3U8Link(m3u8)

        if(responseM3U8) {
          var responseTS
          if (vmusicError){
            responseTS = await getTSFileFromLink(tsUrl(responseM3U8))
          }

          try {
            const response = await fetch(m3u8Url)
            const responseText = await response.text()
            const urlPattern = /https?:\/\/[^\s"]+/g
            const match = responseText.match(urlPattern)
            await fetchWithTimeout(match[0], 3000)
          } catch(error) {
            console.log(error)
            handleMp4Url()
            return
          }
          
          if((!vmusicError || responseTS === 200) ) {

            setPatient({
              intro: introURL ? introURL : "",
              src: m3u8Url,
              secondarySrcs: [mp4Url, m3u8],
              type: 'application/x-mpegURL',
              date: date,
              examStatus: examStatus,
              showRate: showRate
            })
          } else {
            handleMp4Url();
          }
          
        } else {
          handleMp4Url();
        }
      } catch (error) {
        toLoading()
      }
    }

    const requestShareToken = async () => {
        try {
            const { data } = await getShareToken()

            setShareLink(data)

        } catch (error) {
            console.log(error)
        }
    }

    const showStatusDownloadMP4 = (status) => {
  
      switch (status) {
        case 'enqueued':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.babyDetails'), null)
          break;
        case 'generating':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.preparingVideo'), null)
          break;
        case 'ready':
          showSnackBar(t('alertMessages.concluded'), 'success', '')
          break;
        case 'error':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.error'))      
          setSupportLabelError(t('dialog.errorDownload'));
          setCallSupport(true)
          break;
        case 'no_content':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.noContent'))
          break;
        default:
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.downloadError'))
      }
    };

    const getDownloadLink = async () => {

      const { hash } = shareLink;
      setIsLoading(true)
      showStatusDownloadMP4("enqueued")
      const intervalId = setInterval(async () => {
        try {      
          const { data } = await getUrlDownloadExam(hash)

          showStatusDownloadMP4(data.status)
    
          if(data.status === 'ready') {
            clearInterval(intervalId)
            window.open(data.url, '_self')
            mixpanel.track('WEBAPP_CLICK_ON_DOWNLOAD', { 
              distinct_id: globalPhone,
              'DOWNLOAD_DONE': true,
              'LINK_STATUS': 'ready',
            });
            setShowDialog(patient.showRate)
            setIsLoading(false)
          }
        } catch(error) {
            mixpanel.track('WEBAPP_CLICK_ON_DOWNLOAD', { 
              distinct_id: globalPhone,
              'DOWNLOAD_DONE': false,
              'LINK_STATUS': 'error',
            });
            handleMessage(t('alertMessages.error'), 'waiting', t('alertMessages.error'))
            setCallSupport(true)
            clearInterval(intervalId)
            setIsLoading(false)
          } 
      }, 3000)

      setTimeout(() => {
        clearInterval(intervalId)
      }, 60000 * 10)
    };

    const handleMessage = (message, type, secondMessage, timeToHide) => {
        showSnackBar(message, type, secondMessage, timeToHide);
    };
     
    const formatDate = (date, language) => {
      const parsedDate = parseISO(date);
      const formatPattern = language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
      return format(parsedDate, formatPattern);
    };
    
    const formatTime = (date, language) => {
      const parsedDate = parseISO(date);
      const timeFormat = language === 'en-US' ? 'h:mm a' : 'HH:mm';
      return format(parsedDate, timeFormat);
    };

    const handleSupportMessage = (supportMessage) => {
      const phoneNumber = '551124245299'; 
      let message = ''
      if (supportMessage) {
        message = t('supportMessages.messageViewUser');
      }
      else {
        message = t('supportMessages.messageHelp');
      }
  
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/${phoneNumber}/?text=${encodedMessage}`;
      window.open(whatsappUrl, '_blank');
      mixpanel.track('CALL_SUPPORT_SOLUTION_ASSISTA', { 
        distinct_id: globalPhone,
        'CALL_SUPPORT': true,
        'PHONE_SUPPORT': phoneNumber,
        'URL_SUPPORT': whatsappUrl,
        'MESSAGE_SUPPORT': message,
      });
    }

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    const handleClickOpen = (unit) => {
      setOpen(true);
      setUnit(unit)
    };
  
    const handleClose = () => {
      setOpen(false);
      setUnit(null)
    };

    return (
        patient ? (
          <Container>
            <Header> 
              <ContainerLogo url={setting.logoUrl}></ContainerLogo>
            </Header>
            
            <Main>
              <ContainerDescription>
                <LabelExam>{t('watch.examDay', { date: formatDate(patient.date, i18n.language) })}</LabelExam>
                  
                <ContainerLabel>
                  <InfoLabel>{t('watch.realized', { date: formatTime(patient.date, i18n.language) })}</InfoLabel>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    {patient.examStatus === STATUS_LIVE && <div style={{ width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'red' }}></div>}
                    <InfoLabel>{getStatusLabel(patient.examStatus, i18n)}</InfoLabel>
                  </div>  
                </ContainerLabel>
              </ContainerDescription>

              <PlayerContainer>
                <Player
                  options={{ 
                    sources: [
                      { src: patient.intro, type: 'video/mp4' },
                      { src: patient.src, type: patient.type }
                    ],
                    secondarySrcs: patient.secondarySrcs,
                  }}
                  onError={()=> setCallSupport(true)}
                  globalPhone={globalPhone}
                />
              </PlayerContainer>

              <ContainerButton>
                <SecondButton className="gtm-btn" id='gtm-btn-download' variant="contained" type="button" onClick={getDownloadLink} disabled={isLoading || callSupport || patient.examStatus === STATUS_LIVE}>
                    <img 
                        style={{
                          padding: '0px 6px 0px 0px',
                          pointerEvents: 'none',
                        }}
                        src={Download} 
                    /> 
                    {t('buttons.download')}
                </SecondButton>

                <ShareMenu className="gtm-btn" id="gtm-btn-share" globalPhone={globalPhone} exam={shareLink.exam} hash={shareLink.hash} examType={patient.examStatus} disabled={callSupport} />
              </ContainerButton>
              
              <ScheduleContainer>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                  style={{ 
                    width: '100%', 
                  }}
                >
                  {
                    units.map((unit, i) => (
                        <ScheduleSection key={i}>
                          <UnitDescription>
                            {unit.description}
                          </UnitDescription>
                          
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}>
                            {unit.address && unit.city && unit.state &&  unit.zipcode ? 
                              <>
                                <img 
                                    style={{
                                      padding: '0px 6px 0px 0px',
                                      pointerEvents: 'none',
                                    }}
                                    src={addressIcon} 
                                  />
                                <InfoLabel>
                                {unit.address}, {unit.city} - {unit.state}, {unit.zipcode}
                                </InfoLabel>
                              </> : null
                            }
                          </div>

                          <ScheduleContainerButton>
                            {unit.whatsapp || unit.phone || unit.site ?
                              <SecondButton 
                                className="gtm-btn" 
                                id='gtm-btn-call' 
                                variant="contained" 
                                type="button"
                                onClick={() => handleClickOpen(unit)}
                              >
                                <img 
                                    style={{
                                      padding: '0px 6px 0px 0px',
                                      pointerEvents: 'none',
                                    }}
                                    src={calendarIcon} 
                                /> 
                                {t('buttons.schedule')}
                              </SecondButton> : null
                            }
                          </ScheduleContainerButton>
                        </ScheduleSection>
                    ))}
                </SwipeableViews>
                <MobileStepper
                  steps={units.length}
                  style={{
                    display: units.length > 1 ? 'flex' : 'none',
                  }}
                  sx={
                    {
                      '.MuiMobileStepper-dotActive': {
                        backgroundColor: settings.loginButton.backgroundColor || '#0d8b72',
                      }
                    }
                  }
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === units.length - 1}
                    >
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                    </Button>
                  }
                />
              </ScheduleContainer>

              {showDialog 
                    && (isMobile || isTablet) 
                    && <DialogComponent onShare={shareLink.hash} open={true} type="rating" />
              }
              {callSupport 
                  && <DialogComponent globalPhone={globalPhone} open={true} type="support" supportLabelError={supportLabelError} />                        
              }

              <ScheduleChannelDialog 
                open={open}
                onClose={handleClose}
                unit={unit}
              />
            </Main>

            <Footer>

              <TextButton onClick={() => handleSupportMessage(true) }>
                {t('buttons.help')}
              </TextButton>

              <Divider />

              <SelectLanguageContainer>
                <span className='selected-language'>{t("changeLan.label")}</span>
                
                <SelectLanguageButtonContainer>
                  <button 
                      className='selected-language-button' 
                      style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
                      onClick={() => { i18n.changeLanguage('pt-BR') }}
                  >
                      Português
                  </button>
                  
                  <button 
                      className='selected-language-button' 
                      style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
                      onClick={() => { i18n.changeLanguage('en-US') }}
                  >
                      English
                  </button>
                  
                  <button 
                      className='selected-language-button' 
                      style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
                      onClick={() => { i18n.changeLanguage('es') }}
                  >
                      Español
                  </button>
                </SelectLanguageButtonContainer>
              </SelectLanguageContainer>

              <Divider />

              <PoliciesContainer>
                <a
                  href="https://documentos.vlabhealth.com/POLITICADEPRIVACIDADE.pdf"
                  target='_blank'
                  rel="noopener noreferrer"
                  className='terms-link' 
                >
                  {`${t('terms.privacyPolitics')}`}
                </a>

                <a
                  href="https://documentos.vlabhealth.com/TermosUsoConvidados.pdf"
                  target='_blank'
                  rel="noopener noreferrer"
                  className='terms-link' 
                >
                  {`${t('terms.termsOfUse')}`}
                </a>

                <a
                  href="https://documentos.vlabhealth.com/TermoDeColetaDeDadosDeSaúde.pdf"
                  target='_blank'
                  rel="noopener noreferrer"
                  className='terms-link' 
                >
                  
                  {`${t('terms.healthTerms')}`}
                </a>
              </PoliciesContainer>
            </Footer>
          </Container>
        ) : (
          <ContainerBackgroundLoading>
            <ContainerCenter>
                <LabelLoading style={{color: '#ECF2F8', fontWeight: 800}}>  {t('loadingExam')} </LabelLoading>
            </ContainerCenter>
          </ContainerBackgroundLoading>
        )
    )
}

export default Medstreamer