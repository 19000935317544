import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { SecondButton } from '../style';
import styled from 'styled-components';

import whatsappIcon from '../../../assets/icon/whatsapp.svg';
import phoneIcon from '../../../assets/icon/phone.svg';
import onlineIcon from '../../../assets/icon/globe.svg';
import { useMixpanel } from 'react-mixpanel-browser';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { getGlobalPhone } from '../../../utils/functions';


const ListStyled = styled(List)`
    padding: 16px !important;
    display: flex;
    flex-direction: column;
    gap: 16px;

    min-width: 248px;
`;

const DialogStyled = styled(Dialog)`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const DialogTitleStyled = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 16px;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;   

    span {
        line-height: normal;
    }
`;

function ScheduleChannelDialog(props) {
  const { onClose, open, unit } = props;
  const { t, i18n } = useTranslation();
  const mixpanel = useMixpanel();
  const globalPhone = getGlobalPhone();

  const handleClose = () => {
    onClose();
  };

  const handleSchedule = (type) => {

    switch (type) {
      case 'whatsapp':
        window.open(`https://wa.me/${unit.whatsapp}`, '_blank');
        break;
      case 'phone':
        window.open(`tel:${unit.phone}`, '_blank');
        break;
      case 'online':
        window.open(unit.site, '_blank');
        break;
      default:
        break;
    }

    mixpanel.track('SCHEDULE_ASSISTA', { 
      distinct_id: globalPhone,
      'SCHEDULE_ASSISTA': true,
      'CHANNEL': type,
      'UNIT_CODE': unit.code,
      'UNIT_DESCRIPTION': unit.description,
      'WHATSAPP': unit.whatsapp,
      'PHONE': unit.phone,
      'SITE': unit.site,
    });
  }


  return (
    unit ?
    <DialogStyled onClose={handleClose} open={open}>
      <DialogTitleStyled>
        <span>
            {t('dialog.schedule')}
        </span>
        
        <Close onClick={handleClose} style={{ fill: '#8B98A3' }} />
      </DialogTitleStyled>
      <ListStyled>
        {unit.whatsapp && 
            <SecondButton 
              className="gtm-btn" 
              id='gtm-btn-call' 
              variant="contained" 
              type="button"
              onClick={() => handleSchedule('whatsapp')}
            >   
              <img 
                  style={{
                  padding: '0px 6px 0px 0px',
                  pointerEvents: 'none',
                  }}
                  src={whatsappIcon} 
              /> 
              {t('buttons.whatsapp')}
          </SecondButton>
        }

        {unit.phone && 
          <SecondButton 
              className="gtm-btn" 
              id='gtm-btn-call' 
              variant="contained" 
              type="button"
              onClick={() => handleSchedule('phone')}
          >
              <img 
                  style={{
                  padding: '0px 6px 0px 0px',
                  pointerEvents: 'none',
                  }}
                  src={phoneIcon} 
              /> 
              {t('buttons.phone')}
          </SecondButton>
        }

        {unit.site &&
          <SecondButton 
            className="gtm-btn" 
            id='gtm-btn-call' 
            variant="contained" 
            type="button"
            onClick={() => handleSchedule('online')}
          >
            <img 
                style={{
                padding: '0px 6px 0px 0px',
                pointerEvents: 'none',
                }}
                src={onlineIcon} 
            /> 
            {t('buttons.online')}
        </SecondButton> 
       }
      </ListStyled>
    </DialogStyled>
    : null
  );
}

ScheduleChannelDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  unit: PropTypes.object,
};

export default ScheduleChannelDialog;