import styled from "styled-components";
import Input from "../../components/Input";

export const StyledInput = styled(Input)`
  background: white;
  border: 1px solid #d2d2d2;
  flex: 1;
  &:focus {
    border-color: #5d4deb;
  }
`;

export const Wrapper = styled.div`
  background: #f7f3f2;
  height: 100vh;
  display: flex;

  @media screen and (max-width: 960px) {
    min-height: auto;
    overflow-y: scroll;
  }

  * {
    &::selection {
      background: #5d4deb;
      color: white;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ hasPaymentMethod }) =>
    hasPaymentMethod ? "1fr 1fr" : "1fr"};
  grid-template-rows: 1fr;
  flex: 1;
  padding: 124px;
  column-gap: 124px;
  overflow-y: scroll;

  @media screen and (max-width: 1360px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 16px;
    gap: 32px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(210, 210, 210, 0.5);
  margin: 16px 0;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 32px;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > p {
    font-family: "Karla";
    font-size: 26px;
    font-weight: 700;
    line-height: 28.6px;
    text-align: right;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins";
  /* position: sticky;
  top: 0;
  left: 0;
  background: #f7f3f2; */
  > svg {
    cursor: pointer;
    color: black;
    size: 16px;
  }
`;

export const EmptyCart = styled.p`
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins";
`;

export const PaymentMethodCard = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #5d4deb;
  background-color: white;
  padding: 16px 0;
`;

export const PaymentMethodOption = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 96.5%;
  padding: 0 8px;
  height: 48px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  &:hover {
    transition: all 0.5s;
    background: #5d4deb24;
  }
`;
