import styled from "styled-components";
import Input from "../../../../components/Input";
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 16px 0 16px;
  width: calc(100% - 32px);

  > p {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? "#5D4DEB" : "#D2D2D2")};
`;

export const StyledInput = styled(Input)`
  background: white;
  border: ${({ isError }) =>
    isError ? "1px solid #ff0a0a !important" : "1px solid #d2d2d2"};
  flex: 1;
  &:focus {
    border-color: #5d4deb;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #5d4deb;
`;

export const InputsContainer = styled.div`
  padding: 16px;
  flex: 1;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
`;
