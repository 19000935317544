import { DEBUG_MODE, DEV_MODE } from './env'

const logger = type =>
    function () {
        if (DEV_MODE || DEBUG_MODE) {
            const args = Array.from(arguments)
            const logType = `[VLAB - ${type.toLowerCase()}]:`

            console[type](logType, ...args)
        }
    }

export const log = logger('log')
export const info = logger('info')
export const error = logger('error')
export const warn = logger('warn')

export default { log, info, error, warn }
