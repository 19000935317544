import React, { createContext, useContext } from "react"
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Slide } from "@mui/material";

import Info from '../../src/assets/Info.svg';
import Loading from '../../src/assets/loading-spinner.svg'
import Success from '../../src/assets/success.png'

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {

  let currentBackgroundColor
  let currentColor
  let icon

  switch (props.type) {
    case 'error':
      currentBackgroundColor = '#FDEBEB'
      currentColor = '#E20101'
      icon = Info
      break;
    case 'success':
      currentBackgroundColor = '#51525F'
      currentColor = '#F7F8FC'
      icon = Success
      break;
    case 'waiting':
      currentBackgroundColor = '#51525F'
      currentColor = '#F7F8FC'
      icon = Loading;
      break;
    default:
      currentBackgroundColor = 'blue'
      currentColor = 'black'
  }

  return <MuiAlert 
    sx={{ backgroundColor: currentBackgroundColor, 
          color: currentColor, 
          display: 'flex', 
          alignItems: 'center', 
          fontWeight: 600,
        }} 
    icon={<img src={icon} width={24} height={24} alt="snack icon" />}
    elevation={6} 
    ref={ref} 
    variant="filled" 
    {...props} 
    />;
});

const SnackBarContext = createContext({})

const SnackBarProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [secondMessage, setSecondMessage] = React.useState("")
  const [typeColor, setTypeColor] = React.useState("info")
  const [type, setType] = React.useState("")
  const [timeToHide, setTimeToHide] = React.useState(3000)

  const showSnackBar = (text, currentType, secondaryText = "", timeToHide = 3000) => {
    setMessage(text)
    setType(currentType)
    setSecondMessage(secondaryText);
    setOpen(true)
    setTimeToHide(timeToHide)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={timeToHide}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={props => <Slide {...props} direction="down" />}
      >
        <Alert onClose={handleClose} type={type} >
        <div>{message}</div>
        {secondMessage && <div style={{ fontSize: '12px', fontWeight: '400' ,marginTop: '4px'  }}>{secondMessage}</div>}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  )
}

const useSnackBar = () => {
  const context = useContext(SnackBarContext)

  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider")
  }

  return context
}

export { SnackBarProvider, useSnackBar }