import fetcher from '../../fetcher'

const EXAM_URL = '/patient'

const PatientFetcher =
    ({
        children
    }) => {

        async function request() {
            try {
                const { data } = await fetcher.get(EXAM_URL)

                return data
            } catch (error) {
                return Promise.reject(error)
            }
        }

        return children({
            request
        })
    }

export default PatientFetcher
