import { useEffect, useState } from "react";
import { Form } from "./components/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatPrice } from "../../utils/formatPrice";

import {
  Container,
  Divider,
  Row,
  Section,
  StyledInput,
  Wrapper,
  PaymentMethodCard,
  PaymentMethodOption,
} from "./style";
import { validateEmail } from "../../utils/validateEmail";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { hasLastName } from "../../utils/hasLastName";
import { useExam } from "../../contexts/examContext";
import FlexContainer from "../../components/Flex";
import { FaBarcode, FaPix } from "react-icons/fa6";
import VisaIcon from "../../components/Icons/VisaIcon";
import MasterCardIcon from "../../components/Icons/MasterCardIcon";
import { Checkbox } from "../../components/Checkbox";
import { SubmitButton } from "../login/components/buttons";
import { boletoBuy, cardBuy, pixBuy } from "../../lib/api";
import { cpfMask } from "../../utils/masks";
import { useSnackBar } from "../../contexts/snackbar";
import { useTranslation } from "react-i18next";
const defaultValues = {
  email: "",
  name: "",
  birthdate: "",
  cpf: "",
  city: "",
  country: "BR",
  line1: "",
  line2: "",
  state: "",
  zip: "",
  cardNumber: "",
  validity: "",
  CVC: "",
  titular: "",
};

export const Checkout = () => {
  // eslint-disable-next-line
  const [step, setStep] = useState(1);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const paymentMethodsOptions = [
    { value: "pix", label: "Pix" },
    { value: "boleto", label: t("payment.boleto") },
    { value: "card", label: t("payment.creditCard") },
  ];
  const [pixForm, setPixForm] = useState({
    email: "",
    name: "",
  });

  const { showSnackBar } = useSnackBar();

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [form, setForm] = useState(defaultValues);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isSendingPayment, setIsSendingPayment] = useState(false);
  const navigate = useNavigate();
  const [exam] = useExam();

  const nextStep = () => {
    if (paymentMethod.value === "card" && step === 1) {
      setStep(2);
    }
    setStep((prevStep) => prevStep + 1);
  };

  const onClickStep = (clickedStep) => {
    if (clickedStep >= step) return;
    setStep(clickedStep);
  };

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  const handleSubmitPurchase = async () => {
    setIsSendingPayment(true);
    try {
      if (paymentMethod.value === "pix") {
        const response = await pixBuy({
          data: {
            cpf: pixForm.cpf,
            email: pixForm.email,
            name: pixForm.name,
          },
        });
        navigate(`/finish-purchase`, {
          state: {
            id: "",
            payment_method: "pix",
            value: exam.payment.price,
            ...response.data.order,
          },
        });
      } else if (paymentMethod.value === "boleto") {
        const response = await boletoBuy({
          data: {
            address: {
              city: form.city,
              country: form.country,
              line1: form.line1,
              line2: form.line2,
              state: form.state,
              zip: form.zip,
            },
            email: form.email,
            name: form.name,
            cpf: form.cpf,
          },
        });
        navigate(`/finish-purchase`, {
          state: {
            id: "",
            payment_method: "boleto",
            value: exam.payment.price,
            ...response.data,
          },
        });
      } else {
        if (!stripe || !elements) {
          console.error("Stripe não foi inicializado corretamente.");
          return;
        }

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) return;

        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
          showSnackBar(t("payment.errorToPay"), "error");

          return;
        }

        const response = await cardBuy({
          data: {
            card_token: token.id,
            email: form.email,
            name: form.name,
          },
        });

        navigate(`/finish-purchase`, {
          state: {
            id: "",
            payment_method: "card",
            value: exam.payment.price,
            ...response.data,
          },
        });
      }
    } catch {
      showSnackBar(t("payment.errorToPay"), "error");
      setIsSendingPayment(false);
    }
  };

  const checkSubmitIsDisabled = () => {
    if (!paymentMethod?.value) return true;
    if (paymentMethod.value === "pix") {
      return !!(
        pixForm.email === "" ||
        !validateEmail(pixForm.email) ||
        !hasLastName(pixForm.name) ||
        !pixForm.cpf ||
        pixForm?.cpf?.length < 14
      );
    } else if (paymentMethod.value === "boleto") {
      return !!(step < 3);
    } else {
      return !!(step < 3 || !isCardComplete);
    }
  };

  const onChangePaymentMethod = (option) => {
    setPaymentMethod(option);
  };

  useEffect(() => {
    document
      .querySelector(`#step-${step}`)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [step]);

  const isDisabled = checkSubmitIsDisabled();

  return (
    <Wrapper>
      <Container hasPaymentMethod={!!paymentMethod}>
        <Section style={{ padding: "0 16px" }}>
          <Row>
            <p style={{ fontFamily: "Archivo" }}>Total</p>
            <p style={{ color: "#5D4DEB", fontFamily: "Archivo" }}>
              {formatPrice(exam.payment.price)}
            </p>
          </Row>
          <Divider />

          <PaymentMethodCard>
            <h5 style={{ margin: "0 0 16px 16px" }}>
              {t("payment.selectPayment")}
            </h5>
            <FlexContainer style={{ flexDirection: "column" }} gap="8px">
              {paymentMethodsOptions.map((option) => (
                <PaymentMethodOption
                  onClick={() => onChangePaymentMethod(option)}
                >
                  <FlexContainer alignItems="center" gap="8px">
                    {paymentMethod && paymentMethod.value === option.value ? (
                      <Checkbox selected={true} size="16px" />
                    ) : (
                      <div style={{ width: "16px" }} />
                    )}

                    <p style={{ fontSize: "14px" }}>{option.label}</p>
                  </FlexContainer>
                  {option.value === "pix" && (
                    <FaPix color="#4DB6AC" style={{ margin: "0 16px" }} />
                  )}
                  {option.value === "boleto" && (
                    <FaBarcode style={{ margin: "0 16px" }} />
                  )}

                  {option.value === "card" && (
                    <FlexContainer
                      alignItems="center"
                      gap="8px"
                      margin="0 16px"
                    >
                      <VisaIcon />
                      <MasterCardIcon />
                    </FlexContainer>
                  )}
                </PaymentMethodOption>
              ))}
            </FlexContainer>
          </PaymentMethodCard>
        </Section>
        {paymentMethod && (
          <Section style={{ background: "white", height: "fit-content" }}>
            {paymentMethod.value === "pix" ? (
              <Form
                id="step-1"
                header={t("payment.paymentDetails")}
                currentStep={step}
                formStep={1}
                nextStep={nextStep}
                onClickStep={onClickStep}
                setForm={setForm}
                form={form}
                paymentMethod={paymentMethod.value}
              >
                <StyledInput
                  autoFocus
                  placeholder="E-mail"
                  value={pixForm.email}
                  onChange={(e) => {
                    setPixForm((prev) => ({ ...prev, email: e.target.value }));
                  }}
                />
                <StyledInput
                  placeholder={t("payment.name")}
                  value={pixForm.name}
                  onChange={(e) => {
                    setPixForm((prev) => ({ ...prev, name: e.target.value }));
                  }}
                />
                <StyledInput
                  placeholder="CPF"
                  maxLength={14}
                  value={cpfMask(pixForm.cpf)}
                  onChange={(e) => {
                    setPixForm((prev) => ({
                      ...prev,
                      cpf: e.target.value,
                    }));
                  }}
                />
              </Form>
            ) : (
              <>
                <Form
                  id="step-1"
                  header={t("payment.paymentDetails")}
                  currentStep={step}
                  formStep={1}
                  nextStep={nextStep}
                  onClickStep={onClickStep}
                  setForm={setForm}
                  form={form}
                  paymentMethod={paymentMethod.value}
                />
                {paymentMethod.value === "boleto" && (
                  <Form
                    id="step-2"
                    header={t("payment.addressToCharge")}
                    currentStep={step}
                    formStep={2}
                    nextStep={nextStep}
                    onClickStep={onClickStep}
                    setForm={setForm}
                    form={form}
                  />
                )}
                {paymentMethod.value === "card" && (
                  <Form
                    id="step-3"
                    header={t("payment.payment")}
                    currentStep={step}
                    formStep={3}
                    nextStep={nextStep}
                    onClickStep={onClickStep}
                    setForm={setForm}
                    form={form}
                    handleCardChange={handleCardChange}
                  />
                )}
              </>
            )}

            <SubmitButton
              color="#624DEB"
              disabled={isDisabled || isSendingPayment}
              fontColor={"white"}
              onClick={handleSubmitPurchase}
            >
              {t("payment.endBuy")}
            </SubmitButton>
          </Section>
        )}
      </Container>
    </Wrapper>
  );
};
