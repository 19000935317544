import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import styled, { css } from 'styled-components';
import { PatientContext } from '../../../contexts';
import WhatsappIcon from '../../../assets/whatsapp-green.svg'
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

const StyledFab = styled(Fab)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid transparent;
    text-transform: none;
    font-size: 13.3px;
    opacity: 1;
    transition: opacity 1s ease 0s;
    img {
      width: 36px;
      height: 36px;
    }

    ${props => css`
      background-color: ${props.colorbackground};
      color: ${props.colorfont};
    `}

    &:hover {
      opacity: 0.7;
      cursor: pointer;
      background-color: ${props => props.colorbackground};
      color: ${props => props.colorfont};
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }  
    
    &:active {
      box-shadow: none;
    }
  }
`;

export default function FloatButton({ supportMessage, globalPhone }) {
  const { settings } = useContext(PatientContext);
  const { loginButton } = settings;
  const { t, i18n } = useTranslation();
  const mixpanel = useMixpanel()

  const handleSupportMessage = () => {
    const phoneNumber = '551124245299'; 
    let message = ''
    if (supportMessage) {
      message = t('supportMessages.messageViewUser');
    }
    else {
      message = t('supportMessages.messageHelp');
    }

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
    mixpanel.track('CALL_SUPPORT_SOLUTION_ASSISTA', { 
      distinct_id: globalPhone,
      'CALL_SUPPORT': true,
      'PHONE_SUPPORT': phoneNumber,
      'URL_SUPPORT': whatsappUrl,
      'MESSAGE_SUPPORT': message,
    });
  };

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <StyledFab
        variant="extended"
        size="small"
        color="primary"
        aria-label="add"
        style={{ backgroundColor: '#25D366' }} 
        onClick={handleSupportMessage} 
      >
        <img src={WhatsappIcon} alt="WhatsApp" />
      </StyledFab>
    </Box>
  );
}
