export const fetchWithTimeout = (url, timeout) => {
  return new Promise((resolve, reject) => {
    const controller = new AbortController()
    
    const signal = controller.signal

    const timer = setTimeout(() => {
      controller.abort()
      reject(new Error('Request timed out'))
    }, timeout)

    fetch(url, { signal })
      .then(response => {
        if (!response.ok) {
          reject(new Error('Network response was not ok'))
        } else {
          resolve(response) 
        }
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          reject(new Error('Fetch aborted due to timeout'))
        } else {
          reject(err)
        }
      })
      .finally(() => {
        clearTimeout(timer)
      })
  })
}
