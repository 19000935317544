import styled from "styled-components";

const Input = styled.input`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  background: #dad0ff;
  color: #7b61ff;
  &::placeholder {
    color: #7b61ff;
  }
  &:focus-visible {
    outline: none;
  }
  border: ${({ error }) => (error ? "1px solid #FF0000 " : "")};
`;

export default Input;
