import React from 'react'
import { createRoot } from 'react-dom/client';

import App from './app'
import { registerEnvUtils } from '../src/utils/env'

import './i18n'

registerEnvUtils()

const root = createRoot(document.getElementById('root'));
root.render(<App />);
