import React from 'react'

export function VbabyIconDark({width, height, viewBox}) {

    return (
        <div style={{ width: width, height: height }}>
        <svg 
            width={width} 
            height={height} 
            viewBox={viewBox} 
            preserveAspectRatio="xMidYMid meet" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M24.4401 51.38L11.7701 16.71H1.12012L15.3001 54.71H25.7201L24.4401 51.38Z" fill="#7B61FF"/>
            <path d="M17.3901 51.38L30.0701 16.71H40.7201L26.5401 54.71H16.1201L17.3901 51.38Z" fill="#A692F2"/>
            <path d="M47.5 42.3799L56.36 35.8799L47.5 29.3799V42.3799Z" fill="#7B61FF"/>
            <path d="M97.32 18.0599C94.8 16.4299 92 15.61 88.92 15.61C86.1 15.61 83.66 16.2799 81.61 17.6299C80.19 18.5599 78.99 19.82 78.02 21.37V0.949951H68.77V54.7099H74.8L77.07 48.07C78.23 50.6 79.77 52.52 81.68 53.81C83.65 55.14 85.83 55.81 88.2 55.81C91.41 55.81 94.34 54.9699 96.98 53.2799C99.62 51.5999 101.71 49.2299 103.27 46.1899C104.83 43.1499 105.6 39.5899 105.6 35.5099C105.6 31.4299 104.85 27.98 103.35 24.99C101.85 22 99.84 19.6899 97.32 18.0499V18.0599ZM95.05 41.79C94.23 43.57 93.11 44.9499 91.7 45.9399C90.29 46.9299 88.68 47.43 86.88 47.43C85.42 47.43 84.01 47.0299 82.65 46.2299C81.29 45.4299 80.18 44.32 79.32 42.88C78.46 41.44 78.03 39.7699 78.03 37.8699V33.32C78.03 31.34 78.44 29.64 79.26 28.25C80.08 26.86 81.16 25.78 82.49 25.04C83.82 24.29 85.26 23.92 86.8 23.92C88.65 23.92 90.29 24.4 91.72 25.37C93.14 26.34 94.26 27.69 95.07 29.43C95.88 31.17 96.28 33.2 96.28 35.52C96.28 37.84 95.87 40.01 95.05 41.79V41.79Z" fill="#A692F2"/>
            <path d="M137.51 17.2C135.05 16.14 132.18 15.61 128.89 15.61C125.26 15.61 122.14 16.03 119.54 16.88C116.94 17.73 114.95 19.06 113.56 20.88C112.18 22.7 111.48 25.04 111.48 27.91H121.59C121.59 26.73 121.88 25.74 122.47 24.91C123.06 24.09 123.9 23.45 125 23.01C126.1 22.57 127.39 22.34 128.88 22.34C131.47 22.34 133.3 22.98 134.39 24.26C135.47 25.54 136.02 27.51 136.02 30.18V34.11C134.48 33.7 132.93 33.34 131.38 33.08C129.54 32.77 127.59 32.61 125.52 32.61C122.36 32.61 119.62 33.02 117.29 33.84C114.96 34.66 113.17 35.89 111.9 37.52C110.63 39.15 110 41.17 110 43.57C110 45.74 110.55 47.75 111.65 49.6C112.75 51.45 114.39 52.95 116.57 54.09C118.75 55.23 121.47 55.79 124.74 55.79C127.69 55.79 130.17 55.1 132.17 53.71C134.02 52.43 135.43 50.73 136.44 48.66L137.21 54.69H145.28V30.5C145.28 27.08 144.6 24.27 143.24 22.08C141.88 19.89 139.97 18.26 137.52 17.2H137.51ZM131.95 47.51C130.62 48.35 129 48.76 127.09 48.76C124.58 48.76 122.75 48.25 121.6 47.23C120.45 46.21 119.88 45 119.88 43.59C119.88 41.66 120.59 40.29 122 39.48C123.41 38.67 125.21 38.27 127.41 38.27C128.9 38.27 130.49 38.4 132.19 38.66C133.56 38.87 134.83 39.17 136.03 39.52V40.62C136.03 41.87 135.68 43.13 134.99 44.38C134.3 45.63 133.29 46.68 131.95 47.51V47.51Z" fill="#A692F2"/>
            <path d="M182.55 18.0599C180.03 16.4299 177.23 15.61 174.15 15.61C171.33 15.61 168.89 16.2799 166.84 17.6299C165.42 18.5599 164.22 19.82 163.25 21.37V0.949951H154V54.7099H160.03L162.3 48.07C163.46 50.6 165 52.52 166.91 53.81C168.88 55.14 171.06 55.81 173.43 55.81C176.64 55.81 179.57 54.9699 182.21 53.2799C184.85 51.5999 186.94 49.2299 188.5 46.1899C190.06 43.1499 190.83 39.5899 190.83 35.5099C190.83 31.4299 190.08 27.98 188.58 24.99C187.08 22 185.07 19.6899 182.55 18.0499V18.0599ZM180.28 41.79C179.46 43.57 178.34 44.9499 176.93 45.9399C175.52 46.9299 173.91 47.43 172.11 47.43C170.65 47.43 169.24 47.0299 167.88 46.2299C166.52 45.4299 165.41 44.32 164.55 42.88C163.69 41.44 163.26 39.7699 163.26 37.8699V33.32C163.26 31.34 163.67 29.64 164.49 28.25C165.31 26.86 166.39 25.78 167.72 25.04C169.05 24.29 170.49 23.92 172.03 23.92C173.88 23.92 175.52 24.4 176.95 25.37C178.37 26.34 179.49 27.69 180.3 29.43C181.11 31.17 181.51 33.2 181.51 35.52C181.51 37.84 181.1 40.01 180.28 41.79V41.79Z" fill="#A692F2"/>
            <path d="M220.15 16.7099L212.21 44.0299L202.99 16.7099H192.49L207.77 54.7199L206.59 58.3999C206.12 59.9699 205.55 61.3399 204.87 62.5299C204.19 63.7199 203.39 64.6499 202.46 65.3099C201.53 65.9799 200.45 66.3099 199.23 66.3099C197.87 66.3099 196.52 66.0999 195.17 65.6799C193.82 65.2599 192.43 64.6099 191 63.7199V71.8699C192.78 72.7299 194.52 73.3699 196.23 73.7699C197.94 74.1699 199.7 74.3799 201.5 74.3799C204.63 74.3799 207.29 73.3699 209.47 71.3599C211.65 69.3499 213.49 66.0999 214.98 61.5999L229.56 16.6899H220.16L220.15 16.7099Z" fill="#A692F2"/>
        </svg>
        </div>
    )
}