import React from 'react'


const VlabIcon = ({width = 54, height= 18}) => {

    return (
            <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7695_3479)">
            <path d="M5.73807 12.1688L2.76337 3.95752H0.262939L3.59216 12.9575H6.03859L5.73807 12.1688Z" fill="#7B61FF"/>
            <path d="M4.08284 12.1688L7.05988 3.95752H9.56032L6.2311 12.9575H3.78467L4.08284 12.1688Z" fill="#A692F2"/>
            <path d="M11.1521 10.0374L13.2323 8.49797L11.1521 6.9585V10.0374Z" fill="#7B61FF"/>
            <path d="M22.849 4.27747C22.2574 3.89141 21.6 3.6972 20.8769 3.6972C20.2148 3.6972 19.6419 3.85589 19.1606 4.17562C18.8272 4.39589 18.5455 4.69431 18.3177 5.06141V0.225098H16.146V12.9577H17.5617L18.0947 11.3851C18.367 11.9843 18.7286 12.439 19.177 12.7446C19.6396 13.0596 20.1514 13.2183 20.7078 13.2183C21.4615 13.2183 22.1494 13.0193 22.7692 12.619C23.389 12.2212 23.8797 11.6598 24.246 10.9398C24.6123 10.2198 24.793 9.37668 24.793 8.41036C24.793 7.44404 24.617 6.62694 24.2648 5.91878C23.9126 5.21062 23.4407 4.66352 22.849 4.2751V4.27747ZM22.3161 9.89773C22.1236 10.3193 21.8606 10.6461 21.5296 10.8806C21.1985 11.1151 20.8205 11.2335 20.3979 11.2335C20.0551 11.2335 19.7241 11.1388 19.4048 10.9493C19.0855 10.7598 18.8249 10.4969 18.623 10.1559C18.421 9.81483 18.3201 9.41931 18.3201 8.96931V7.89168C18.3201 7.42273 18.4163 7.0201 18.6089 6.69089C18.8014 6.36168 19.055 6.10589 19.3672 5.93062C19.6795 5.75299 20.0176 5.66536 20.3791 5.66536C20.8135 5.66536 21.1985 5.77905 21.5343 6.00878C21.8677 6.23852 22.1306 6.55826 22.3208 6.97036C22.511 7.38247 22.6049 7.86326 22.6049 8.41273C22.6049 8.9622 22.5086 9.47615 22.3161 9.89773Z" fill="#A692F2"/>
            <path d="M32.285 4.07384C31.7075 3.82279 31.0337 3.69727 30.2612 3.69727C29.409 3.69727 28.6764 3.79674 28.066 3.99806C27.4556 4.19937 26.9883 4.51437 26.662 4.94542C26.338 5.37648 26.1736 5.93069 26.1736 6.61042H28.5473C28.5473 6.33095 28.6154 6.09648 28.7539 5.8999C28.8924 5.70569 29.0897 5.55411 29.3479 5.4499C29.6062 5.34569 29.909 5.29121 30.2589 5.29121C30.867 5.29121 31.2966 5.44279 31.5525 5.74595C31.8061 6.04911 31.9352 6.51569 31.9352 7.14806V8.07885C31.5736 7.98174 31.2097 7.89648 30.8458 7.8349C30.4138 7.76148 29.956 7.72358 29.47 7.72358C28.7281 7.72358 28.0848 7.82069 27.5377 8.0149C26.9907 8.20911 26.5704 8.50042 26.2723 8.88648C25.9741 9.27253 25.8262 9.75095 25.8262 10.3194C25.8262 10.8333 25.9553 11.3094 26.2136 11.7475C26.4718 12.1857 26.8569 12.541 27.3687 12.811C27.8805 13.081 28.5191 13.2136 29.2869 13.2136C29.9795 13.2136 30.5617 13.0502 31.0313 12.721C31.4657 12.4178 31.7967 12.0152 32.0338 11.5249L32.2146 12.9531H34.1093V7.22385C34.1093 6.41384 33.9497 5.74832 33.6303 5.22963C33.311 4.71095 32.8626 4.3249 32.2874 4.07384H32.285ZM30.9797 11.2525C30.6674 11.4515 30.287 11.5486 29.8386 11.5486C29.2493 11.5486 28.8197 11.4278 28.5497 11.1862C28.2797 10.9446 28.1458 10.6581 28.1458 10.3241C28.1458 9.867 28.3125 9.54253 28.6436 9.35069C28.9746 9.15884 29.3972 9.06411 29.9137 9.06411C30.2636 9.06411 30.6369 9.0949 31.036 9.15648C31.3577 9.20621 31.6558 9.27727 31.9376 9.36016V9.62069C31.9376 9.91674 31.8554 10.2152 31.6934 10.5112C31.5314 10.8073 31.2943 11.056 30.9797 11.2525Z" fill="#A692F2"/>
            <path d="M42.8595 4.27747C42.2679 3.89141 41.6105 3.6972 40.8874 3.6972C40.2253 3.6972 39.6524 3.85589 39.1711 4.17562C38.8377 4.39589 38.556 4.69431 38.3282 5.06141V0.225098H36.1565V12.9577H37.5722L38.1052 11.3851C38.3775 11.9843 38.7391 12.439 39.1875 12.7446C39.6501 13.0596 40.1619 13.2183 40.7183 13.2183C41.472 13.2183 42.1599 13.0193 42.7797 12.619C43.3995 12.2212 43.8902 11.6598 44.2565 10.9398C44.6228 10.2198 44.8035 9.37668 44.8035 8.41036C44.8035 7.44404 44.6275 6.62694 44.2753 5.91878C43.9231 5.21062 43.4512 4.66352 42.8595 4.2751V4.27747ZM42.3266 9.89773C42.1341 10.3193 41.8711 10.6461 41.5401 10.8806C41.209 11.1151 40.831 11.2335 40.4084 11.2335C40.0656 11.2335 39.7346 11.1388 39.4153 10.9493C39.096 10.7598 38.8354 10.4969 38.6334 10.1559C38.4315 9.81483 38.3306 9.41931 38.3306 8.96931V7.89168C38.3306 7.42273 38.4268 7.0201 38.6194 6.69089C38.8119 6.36168 39.0655 6.10589 39.3777 5.93062C39.69 5.75299 40.0281 5.66536 40.3896 5.66536C40.824 5.66536 41.209 5.77905 41.5448 6.00878C41.8781 6.23852 42.1411 6.55826 42.3313 6.97036C42.5214 7.38247 42.6154 7.86326 42.6154 8.41273C42.6154 8.9622 42.5191 9.47615 42.3266 9.89773Z" fill="#A692F2"/>
            <path d="M51.6874 3.95786L49.8232 10.4284L47.6585 3.95786H45.1933L48.7808 12.9602L48.5038 13.8318C48.3934 14.2037 48.2596 14.5281 48.0999 14.81C47.9403 15.0918 47.7525 15.3121 47.5341 15.4684C47.3158 15.6271 47.0622 15.7052 46.7758 15.7052C46.4565 15.7052 46.1395 15.6555 45.8225 15.556C45.5056 15.4565 45.1792 15.3026 44.8435 15.0918V17.0221C45.2614 17.2258 45.6699 17.3773 46.0714 17.4721C46.4729 17.5668 46.8861 17.6165 47.3087 17.6165C48.0436 17.6165 48.6681 17.3773 49.1799 16.9013C49.6918 16.4252 50.1238 15.6555 50.4736 14.5897L53.8967 3.95312H51.6898L51.6874 3.95786Z" fill="#A692F2"/>
            </g>
            <defs>
            <clipPath id="clip0_7695_3479">
            <rect width={width} height={height} fill="white"/>
            </clipPath>
            </defs>
            </svg>
    )
}

export default VlabIcon