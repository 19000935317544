import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;

  ${(props) =>
    props.isPasswordModal &&
    css`
      margin-top: 53.89px;
      z-index: 99;
      &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(5px);
      }
    `}
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  max-width: 394px;
  background-color: #a692f2;

  @media screen and (max-width: 418px) {
    width: 80%;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7b61ff;
  color: white;
  border: 1px solid #fff3ec;
  cursor: pointer;
`;
