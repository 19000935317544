import React, { useContext, useEffect, useState } from 'react'
import { useExam } from '../../contexts/examContext'
import Player from '../../components/player'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Container, ContainerVideo, FlagIcon, Footer, Header, Logo, PlayerContainer, SelectLanguageContainer, StyledOption, StyledOptionList, StyledSelect, StyledSelectWrapper, Subtitle } from './style';
import { getS3BackgroundUrl, getS3LogoUrl } from '../../utils/s3';
import { VbabyIconLight } from '../../assets/icon/vbabyIconLight';
import { VbabyIconUser } from '../../assets/icon/vbabyIconUser';
import { PatientContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { VbabyIconDark } from '../../assets/icon/vbabyIconDark';
import { EndedExam } from '../../components/endedExam';
import DialogComponent from '../watch/components/DialogComponent';
import { isMobileDevice, landscape } from '../../utils/mobile-detect';
import { SkeletonPlayer } from '../../components/skeleton';
import { getGlobalPhone } from '../../utils/functions';
import { API_URL, MUSIC_URL } from '../../fetcher/constants'
import storage from '../../lib/storage'

export function LivePage() {

    const globalPhone = getGlobalPhone();
    const { settings, fetchSettings } = useContext(PatientContext)
    const [exam, setExam] = useExam()
    const navigate = useNavigate()
    const [minHeight, setMinHeight] = useState(0)
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isLandscape, setIsLandscape] = useState(landscape())
    const { t, i18n } = useTranslation();
    const [endedExam, setEndedExam] = useState(false)
    const [callSupport, setCallSupport] = useState(false);
    const [images, setImages] = useState({backgroundImage: '', logoImage: ''})
    const [src, setSrc] = useState('')
    const mobileDevice = isMobileDevice()

    useEffect(() => {
        document.title = `V-Baby - Sala de Ultrassom Ao Vivo - ${settings.name}`;

        setMinHeight(window.innerHeight)
        getImagesFromS3()

        if(exam){
            createSrc(exam.examId, exam.cycle, exam.liveUrl)
        }
        if(exam === null) {
            navigate('/loading')
        }

        
        if(exam && !exam.liveUrl) {
            setEndedExam(true)
        }

        return () => {
            setExam(null)
        }

        
    }, [])

    const getImagesFromS3 = async () => {

        try {
    
            const backgroundImage =  await getS3BackgroundUrl()
            const logoImage =  await getS3LogoUrl()
            
            setImages({backgroundImage, logoImage})
    
        } catch (error) {
    
            console.log('error getImagesFromS3', error)
        }
    }

    const verificarOrientacao = () => {
        setIsLandscape(landscape())
    }

    window.addEventListener("orientationchange", verificarOrientacao);

    const createSrc = async (examId, cycle, m3u8) => {
        let m3u8Url

        try{
          const response = await fetch(`${API_URL}/patient/media/${examId}/${cycle}/vmusic-token`, {
            headers: {
              'Authorization': `Bearer ${storage.getToken()}`,
            }
          })
          const responseString = await response.text()
          m3u8Url = `${MUSIC_URL}/v1/vlab/m3u8/${examId}/${responseString}/vod.m3u8`
          console.log('generate m3u8Url', m3u8Url)
        } catch (e) {
          console.log(e)
          m3u8Url = m3u8
        }
        console.log('createSrc', m3u8Url)
        
        setSrc(m3u8Url)
    }
    

    return(
        
        <Container 
            backgroundColor={
                settings.live && settings.live.backgroundColor1 && settings.live.backgroundColor2 ? 
                `104deg, ${settings.live.backgroundColor1} 20.99%, ${settings.live.backgroundColor2} 59.14%` : "104deg, white 20.99%, white 59.14%" 
            } 
            useBackgroundImage={settings.live && settings.live.backgroundImage && settings.live.useBackgroundImage ? settings.live.useBackgroundImage : false}
            backgroundImageUrl={settings.live && settings.live.backgroundImage ? settings.live.backgroundImage : ''}
            isLandscape={isLandscape}
            isMobile={isMobile} 
            isMinHeight={minHeight < 380}
        >
            <ContainerVideo isLandscape={isLandscape} isMobile={isMobile}>
                <Header isMobile={isMobile} isLandscape={isLandscape}>
                    <Logo url={images.logoImage}/>
                    <p style={{color: 'black'}}>{isLandscape}</p>
                    <Subtitle 
                        textColorLive={settings.live && settings.live.textColorLive ? settings.live.textColorLive : "#5e5e5e"}
                    >{settings.live && settings.live.labelLive ? settings.live.labelLive : t('watch.statusLive')}</Subtitle>
                </Header>
                {   exam ?
                        (endedExam ? 
                            <EndedExam flowType={exam.flowType} organization={exam.organizationDescription} isMobileLandscap={isLandscape} />
                            :
                            src?<PlayerContainer isLandscape={isLandscape} isMobile={isMobile} isMobileLandscap={isLandscape}>
                                <Player
                                    options={{ 
                                    sources: [
                                        { src: exam.introURL, type: 'video/mp4' },
                                        { src: src, type: 'application/x-mpegURL' }
                                    ]
                                    }}
                                    onError={()=> setCallSupport(true)}
                                    isLandscape={isLandscape}
                                    globalPhone={globalPhone}
                                    endedExam={setEndedExam}
                                /> 
                            </PlayerContainer>  
                            : <p>Carregando...</p>  
                        ) : null
                }
                {!endedExam && <Footer isMobile={isMobile} isLandscape={isLandscape}>
                    {!endedExam && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <div style={{ width: 6, height: 6, borderRadius: '50%', backgroundColor: 'red' }} ></div>
                                <p>{t('watch.statusLive')}</p>
                            </div>
                        )
                    }

                    <div style={{ display: 'flex', alignItems: 'center', gap: 8, visibility: 'hidden' }}>
                        <p style={{ fontWeight:  700 }}>12</p>
                        <VbabyIconUser width={14} height={15}/>
                    </div>
                </Footer>}
            </ContainerVideo>

            <div style={mobileDevice ? { margin: '12px 0' } : {position: 'absolute', bottom: '4px'}}>
              <a href='https://www.vlabhealth.com/v-baby' target='_blank' style={{ textDecoration: 'none'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                    <h3 
                        style={{ 
                            marginRight: 6, 
                            color: settings.live && settings.live.textColorLive ? 
                                settings.live.textColorLive : "#5e5e5e", 
                            whiteSpace: 'nowrap' 
                        }}>
                            {t('footer.label')}</h3> 
                        
                        {settings.live && settings.live.logoTheme && settings.live.logoTheme === "dark" ? <VbabyIconDark width={70} viewBox={"0 0 300 70"}/> : <VbabyIconLight width={54} viewBox={"0 0 160 45"}/>}
                </div>
              </a>

              <SelectLanguageContainer>
                    <span className='selected-language'>{t("changeLan.label")}</span>
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('pt-BR') }}
                    >
                        Português
                    </button>
                    |
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('en-US') }}
                    >
                        English
                    </button>
                    |
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('es') }}
                    >
                         Español
                    </button>
                </SelectLanguageContainer>  
            </div>

            {callSupport && (
                <DialogComponent
                    globalPhone={globalPhone}
                    open={true}
                    type="support"
                    supportLabelError={t('alertMessages.examLiveError')}
                />
            )}
        </Container>
    )
}