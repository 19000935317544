import { pipe, prop } from 'ramda'

const SESSION_KEY = 'VLAB_ASSISTA'
const BLUR_PLUGIN_KEY = 'ENABLE_BLUR_PLUGIN'
const VBABY_APP = 'VBABY_APP'

const { sessionStorage } = window

const getCurrentSession = pipe(
    () => sessionStorage.getItem(SESSION_KEY),
    JSON.parse
)

const getCurrentOrganization = pipe(
    () => sessionStorage.getItem(VBABY_APP),
    JSON.parse
)

export const saveSession = data => {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(data))
}

export const saveOrganization = data => {
    sessionStorage.setItem(VBABY_APP, JSON.stringify({organization: data}))
}

export const removeSession = data => {
    sessionStorage.removeItem(SESSION_KEY)
}

export const getToken = pipe(
    getCurrentSession,
    prop('token')
)

export const getOrganization = pipe(
    getCurrentOrganization,
    prop('organization')
)

export const getExamInfos = pipe(
    getCurrentSession,
    ({ accessCode, cpf }) => ({
        accessCode,
        cpf
    })
)

export const toggleBlurPlugin = value => sessionStorage.setItem(BLUR_PLUGIN_KEY, value)

export const getBlurPluginStatus = pipe(
    () => sessionStorage.getItem(BLUR_PLUGIN_KEY),
    value => value === 'true' ? true : false
)

export default {
    getToken,
    saveSession,
    getExamInfos,
    toggleBlurPlugin,
    getBlurPluginStatus,
    saveOrganization,
    getOrganization,
    removeSession
}
