import React, { useContext } from 'react'
import styled from 'styled-components';
import BoxContainer from '../login/components/box-container'
import { BoldLabel, GrayLabel } from '../login/components/labels/label-access';
import { CustomButton } from '../login/components/buttons';
import { useNavigate } from 'react-router-dom';
import { PatientContext } from '../../contexts'; 
import { useTranslation } from 'react-i18next';

const ContainerButton = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  width: 100%;
`;

const NotFound = () => {
    const { t, i18n} = useTranslation()
    const navigate = useNavigate()

    const { settings } = useContext(PatientContext)
    const { loginButton } = settings

    const toLogin = () => {
        navigate('/');
    };

    return (
        <BoxContainer>
            <BoldLabel>{t('notFound.notPage')}</BoldLabel>
            <GrayLabel>{t('notFound.openExam')}</GrayLabel>
                <ContainerButton>
                    <CustomButton                
                        color={loginButton ? loginButton.backgroundColor : "#0166FF"}
                        fontColor={loginButton ? loginButton.textColor : "#fff"} 
                        onBackClick={toLogin}>
                   {t('notFound.goToLogin')}
                    </CustomButton>  
                </ContainerButton>
        </BoxContainer>
    );
}

export default NotFound;