import styled from "styled-components";


export const ContainerBackgroundLoading = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #B7C6D1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  max-width: 700px;
`;

export const LabelLoading = styled.p`
    width: 198px;
    height: 22px;
    font-style: normal;
    font-size: 20px;
    line-height: 21.76px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
`;