import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { differenceInSeconds, subSeconds } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { getS3BackgroundUrl, getS3LogoUrl } from '../../utils/s3';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getGlobalPhone } from '../../utils/functions';
import { PatientContext } from '../../contexts';
import { isMobileDevice, landscape } from '../../utils/mobile-detect';
import Clappr from "clappr";
import { getPatientDemo, getStatusDemo } from '../../lib/api';
import {ContactsContainer, Container, ContainerInfo, ContainerVideo, EndExamContainer, Footer, Header, Logo, PlayerContainer, SecondButton, SelectLanguageContainer, Subtitle} from './style'
import { VbabyIconDark } from '../../assets/icon/vbabyIconDark';
import { VbabyIconLight } from '../../assets/icon/vbabyIconLight';
import { VbabyIconUser } from '../../assets/icon/vbabyIconUser';

import whatsappIcon from '../../assets/icon/whatsapp.svg';
import phoneIcon from '../../assets/icon/phone.svg';
import onlineIcon from '../../assets/icon/globe.svg';

import infoIcon from '../../assets/icon/info.svg';
import closeIcon from '../../assets/icon/close.svg';

const WatchDemo = () => {
    let clapprPlayerRef = null
    const globalPhone = getGlobalPhone();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isLandscape, setIsLandscape] = useState(landscape())
    const { t, i18n } = useTranslation();
    const { settings } = useContext(PatientContext)
    const mobileDevice = isMobileDevice()
    const navigate = useNavigate()
    const setTimeoutRef = React.useRef(null)
    const pauseTimeoutRef = React.useRef(null)
    const duration = React.useRef(100)
    const delay = 2;
    const [minHeight, setMinHeight] = useState(0)
    const [images, setImages] = useState({backgroundImage: '', logoImage: ''})
    const [endedExam, setEndedExam] = useState(false)
    const [showInfo, setShowInfo] = useState(true)

    useEffect(() => {
      document.title = `V-Baby - Sala de Ultrassom Ao Vivo - ${settings.name}`;

      setMinHeight(window.innerHeight)
      setPatientFromExam()
      getImagesFromS3()

      return () => {
        clearInterval(setTimeoutRef.current)
      }

    }, [])

    const getImagesFromS3 = async () => {

      try {
  
        const backgroundImage =  await getS3BackgroundUrl()
        const logoImage =  await getS3LogoUrl()

        setImages({backgroundImage, logoImage})
  
      } catch (error) {
        console.log('error getImagesFromS3', error)
      }
  }

  const toLoading = () => {
    navigate('/loading');
  };

  const setPatientFromExam = async () => {
    try {
      
      const { data } = await getPatientDemo()

      if (data.stopped_at) {
        setEndedExam(true)
        setShowInfo(false)
        return
      }

      clapprPlayerRef = new Clappr.Player({
        parentId: "#player",
        source: "https://static.vlab.live/video-para-site.mp4",
        width: "100%",
        height: "100%",
        controls: false,
        playback: {
          bufferLength: 30
        },
        events: {
          onReady: function() {
            setTimeout(() => {
              duration.current = this.getDuration();
            }, 500);
          }
        }
      });

      const videoElement = document.querySelector('video')
      if (videoElement) {
        videoElement.autoplay = true;
        videoElement.muted = true;
        videoElement.playsInline = true;
        videoElement.controls = false;
      }

      const startedAt = new Date(data.started_at)
      
      const seconds = differenceInSeconds(new Date(), subSeconds(startedAt, data.start_second))
      const currentTime = seconds % duration.current
      const updatedTime = isSafari() ? currentTime - delay - 1 : currentTime - delay
      
      clapprPlayerRef.seek(updatedTime)

      clapprPlayerRef.play()
      clapprPlayerRef.once(Clappr.Events.PLAYER_PLAY, () => setTimeoutRef.current = setInterval(() => {
        pullingSyncVideo(subSeconds(startedAt, data.start_second))
      }, 1500))

    } catch (error) {
      console.error(error)
      toLoading()
    }
  }

  const pullingSyncVideo = async (startedAt) => {
    try {
      const { data } = await getStatusDemo();
      if(data.status !=='paused') {
        if (!(clapprPlayerRef.getCurrentTime() > data.current_second - 5 && clapprPlayerRef.getCurrentTime() <= data.current_second)) {
          const updatedTime = isSafari() ? data.current_second - delay - 1 : data.current_second - delay
          clapprPlayerRef.seek(updatedTime);
        }
      }

      switch (data.status) {
        case 'paused':
          pauseTimeoutRef.current = startedAt;
          clapprPlayerRef.pause();
          break;
        
        case 'on_going':
          if(pauseTimeoutRef.current) {
            const seconds = differenceInSeconds(new Date(), pauseTimeoutRef.current)
            const currentTime = seconds % duration.current
            const updatedTime = isSafari() ? currentTime - delay - 1 : currentTime - delay
            clapprPlayerRef.seek(updatedTime)
            pauseTimeoutRef.current = null
          }
          clapprPlayerRef.play();
          break;
        
        case 'finished':
          setEndedExam(true)
          setShowInfo(false)
          clearInterval(setTimeoutRef.current);
          clapprPlayerRef.stop();
          break;
        
        default:
          console.warn('Estado desconhecido:', data);
          break;
      }
  
    } catch (error) {
      console.error(error);
    }
  };

  const verifyOrientation = () => {
      setIsLandscape(landscape())
  }

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  window.addEventListener("orientationchange", verifyOrientation);

  const handleSchedule = (type) => {

    switch (type) {
      case 'whatsapp':
        window.open(`https://wa.me/+5511953203002`, '_blank');
        break;
      case 'phone':
        window.open(`tel:${unit.phone}`, '_blank');
        break;
      case 'online':
        window.open(unit.site, '_blank');
        break;
      default:
        break;
    }
  }

  return (
    <Container
      backgroundColor={
          settings.live && settings.live.backgroundColor1 && settings.live.backgroundColor2 ? 
          `104deg, ${settings.live.backgroundColor1} 20.99%, ${settings.live.backgroundColor2} 59.14%` : "104deg, white 20.99%, white 59.14%" 
      } 
      useBackgroundImage={settings.live && settings.live.backgroundImage && settings.live.useBackgroundImage ? settings.live.useBackgroundImage : false}
      backgroundImageUrl={settings.live && settings.live.backgroundImage ? settings.live.backgroundImage : ''}
      isLandscape={isLandscape}
      isMobile={isMobile} 
      isMinHeight={minHeight < 380}
    >

    <ContainerVideo isLandscape={isLandscape} isMobile={isMobile}>

      <Header isMobile={isMobile} isLandscape={isLandscape}>
        <Logo url={images.logoImage}/>
        <p style={{color: 'black'}}>{isLandscape}</p>
        <Subtitle
            textColorLive={settings.live && settings.live.textColorLive ? settings.live.textColorLive : "#5e5e5e"}
        >{settings.live && settings.live.labelLive ? settings.live.labelLive : t('watch.statusLive')}</Subtitle>
      </Header>

      {endedExam ? 
        <EndExamContainer isMobileLandscap={isLandscape}>
          <h2>{t('endedExamDemo.title')}</h2>
          <p>
            {t('endedExamDemo.description')}
          </p>
          <ContactsContainer>
            <SecondButton
              className="gtm-btn" 
              id='gtm-btn-call' 
              variant="contained" 
              type="button"
              onClick={() => handleSchedule('whatsapp')}
            >   
              <img 
                  style={{
                  padding: '0px 6px 0px 0px',
                  pointerEvents: 'none',
                  }}
                  src={whatsappIcon} 
              /> 
              {t('buttons.whatsapp')}
            </SecondButton>            
          </ContactsContainer>
        </EndExamContainer> 
        :      
        <PlayerContainer isMobile={isMobile} isLandscape={isLandscape} showInfo={showInfo}>
          <ClapprPlayer id="player" style={{ width: '100%' }} />
          
          {!endedExam && <Footer isMobile={isMobile} isLandscape={isLandscape}>
              {!endedExam && (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          <div style={{ width: 6, height: 6, borderRadius: '50%', backgroundColor: 'red' }} ></div>
                          <p>{t('watch.statusLive')}</p>
                      </div>
                  )
              }

              <div style={{ display: 'flex', alignItems: 'center', gap: 8, visibility: 'hidden' }}>
                  <p style={{ fontWeight:  700 }}>12</p>
                  <VbabyIconUser width={14} height={15}/>
              </div>
            </Footer>
          }
        </PlayerContainer>
      }

        {showInfo && <ContainerInfo>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}>
            <img src={infoIcon} alt='info icon' className='info-icon' />
            <span>
              {t('demo.info')}
            </span>
          </div>
          <button>
            <img 
              src={closeIcon} 
              alt='close icon' 
              onClick={() => setShowInfo(false)}
            />
          </button>
        </ContainerInfo>}
      </ContainerVideo>

      <div style={mobileDevice ? { margin: '12px 0' } : {position: 'absolute', bottom: '4px'}}>
      <a href='https://www.vlabhealth.com/v-baby' target='_blank' style={{ textDecoration: 'none'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <h3 
            style={{ 
              marginRight: 6, 
              color: settings.live && settings.live.textColorLive ? 
                  settings.live.textColorLive : "#5e5e5e", 
              whiteSpace: 'nowrap' 
            }}>
              {t('footer.label')}
          </h3>     
            {settings.live && settings.live.logoTheme && settings.live.logoTheme === "dark" ? <VbabyIconDark width={70} viewBox={"0 0 300 70"}/> : <VbabyIconLight width={54} viewBox={"0 0 160 45"}/>}
        </div>
      </a>

      <SelectLanguageContainer>
        <span className='selected-language'>{t("changeLan.label")}</span>
        <button 
            className='selected-language-button' 
            style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
            onClick={() => { i18n.changeLanguage('pt-BR') }}
        >
            Português
        </button>
        |
        <button 
            className='selected-language-button' 
            style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
            onClick={() => { i18n.changeLanguage('en-US') }}
        >
            English
        </button>
        |
        <button 
            className='selected-language-button' 
            style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
            onClick={() => { i18n.changeLanguage('es') }}
        >
              Español
        </button>
      </SelectLanguageContainer>  
      </div>
    </Container>
  )
}

export default WatchDemo

const ClapprPlayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;

  .media-control-center-panel {
    display: none;
  }

  .media-control-background {
    display: none;
  }

  .media-control-left-panel {
    display: none;
  }

  .spinner-three-bounce {
    display: none !important;
  }

  pointer-events: none;
`