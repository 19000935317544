import React from "react";
import styled, { css } from "styled-components";

const ButtonCustom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  ${(props) => css`
    background-color: ${props.color};
    color: ${props.fontColor};
  `}

  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid transparent;

  opacity: 1;
  transition: opacity 1s ease 0s;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
    color: white;
  }
`;

const ButtonGray = styled.button`
  position: relative;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 42px;
  background: #ffffff;
  border-radius: 8px;
  font-style: normal;
  color: #8b98a3;
  font-weight: 600;
  border: 1px solid #8b98a3;
  &:hover {
    background: #f7f8fc;
    cursor: pointer;
  }
  &:active {
    background: #ecf2f8;
  }
  &:disabled {
    background: #f2f2f2;
    color: #dde5ec;
    border: 1px solid #dde5ec;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const SecondButton = styled.button`
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-radius: 8px;
  font-style: normal;
  color: #8b98a3;
  font-weight: 600;
  border: 1px solid #8b98a3;

  & {
    text-transform: none;
  }
  &:hover {
    background: #f7f8fc;
    cursor: pointer;
  }
  &:active {
    background: #ecf2f8;
  }
  &:disabled {
    background: #f2f2f2;
    color: #dde5ec;
    border: 1px solid #dde5ec;
    cursor: not-allowed;
    pointer-events: none;
    img {
      opacity: 0.3;
    }
  }
`;

const ShareButton = ({ children, onClick, disabled, color, fontColor }) => (
  <ButtonCustom
    color={color}
    fontColor={fontColor}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </ButtonCustom>
);

const SubmitButton = ({
  children,
  color,
  fontColor,
  disabled,
  onClick,
  style,
}) => (
  <ButtonCustom
    variant="contained"
    color={color}
    fontColor={fontColor}
    disabled={disabled}
    onClick={onClick}
    style={style}
  >
    {children}
  </ButtonCustom>
);

const CustomButton = ({ children, onBackClick, color, fontColor }) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <ButtonCustom
      type="button"
      onClick={handleBackClick}
      color={color}
      fontColor={fontColor}
    >
      {children}
    </ButtonCustom>
  );
};

const GrayButton = ({ children, onBackClick }) => {
  const handleBackClick = (event) => {
    event.preventDefault();

    if (onBackClick) {
      onBackClick();
    }
  };

  return <ButtonGray onClick={handleBackClick}>{children}</ButtonGray>;
};

const ResendButton = ({ children, onClick, disabled }) => (
  <SecondButton
    variant="contained"
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </SecondButton>
);

export {
  SubmitButton,
  CustomButton,
  GrayButton,
  ResendButton,
  ShareButton,
  SecondButton,
};
