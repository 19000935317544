import React from 'react'


export function VbabyIconLight({width, height, viewBox}) {

    return(
        <svg
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
            preserveAspectRatio="xMidYMid meet"
        >
            <path d="M15.4137 32.4506L7.4231 10.5537H0.706421L9.64937 34.5537H16.221L15.4137 32.4506Z" fill="#F7DFBF"/>
            <path d="M10.9675 32.4506L18.9644 10.5537H25.6811L16.7381 34.5537H10.1665L10.9675 32.4506Z" fill="#F7F3F2"/>
            <path d="M29.957 26.7662L35.5448 22.6609L29.957 18.5557V26.7662Z" fill="#F7DFBF"/>
            <path d="M61.3772 11.4062C59.7879 10.3767 58.022 9.8588 56.0795 9.8588C54.301 9.8588 52.7622 10.282 51.4693 11.1346C50.5738 11.722 49.8169 12.5177 49.2052 13.4967V0.599854H43.3715V34.5535H47.1744L48.6061 30.3599C49.3376 31.9577 50.3089 33.1704 51.5135 33.9851C52.7559 34.8251 54.1308 35.2483 55.6254 35.2483C57.6499 35.2483 59.4978 34.7177 61.1628 33.6504C62.8277 32.5893 64.1458 31.0925 65.1297 29.1725C66.1136 27.2525 66.5992 25.0041 66.5992 22.4272C66.5992 19.8504 66.1262 17.6714 65.1802 15.783C64.2341 13.8946 62.9665 12.4356 61.3772 11.3999V11.4062ZM59.9456 26.3935C59.4284 27.5177 58.7221 28.3893 57.8328 29.0146C56.9436 29.6399 55.9282 29.9556 54.793 29.9556C53.8722 29.9556 52.9829 29.703 52.1252 29.1977C51.2675 28.6925 50.5674 27.9914 50.0251 27.082C49.4827 26.1725 49.2115 25.1177 49.2115 23.9177V21.0441C49.2115 19.7935 49.4701 18.7199 49.9872 17.842C50.5044 16.9641 51.1855 16.282 52.0243 15.8146C52.8631 15.3409 53.7713 15.1072 54.7425 15.1072C55.9093 15.1072 56.9436 15.4104 57.8454 16.023C58.741 16.6356 59.4473 17.4883 59.9582 18.5872C60.469 19.6862 60.7213 20.9683 60.7213 22.4335C60.7213 23.8988 60.4627 25.2693 59.9456 26.3935V26.3935Z" fill="#F7F3F2"/>
            <path d="M86.724 10.8631C85.1725 10.1936 83.3625 9.85889 81.2876 9.85889C78.9982 9.85889 77.0305 10.1241 75.3908 10.661C73.751 11.1978 72.496 12.0378 71.6193 13.1873C70.749 14.3368 70.3075 15.8147 70.3075 17.6273H76.6837C76.6837 16.882 76.8665 16.2568 77.2386 15.7326C77.6107 15.2147 78.1405 14.8105 78.8343 14.5326C79.528 14.2547 80.3416 14.1094 81.2813 14.1094C82.9147 14.1094 84.0688 14.5136 84.7563 15.322C85.4374 16.1305 85.7843 17.3747 85.7843 19.061V21.5431C84.813 21.2841 83.8355 21.0568 82.8579 20.8926C81.6975 20.6968 80.4677 20.5957 79.1622 20.5957C77.1693 20.5957 75.4412 20.8547 73.9718 21.3726C72.5023 21.8905 71.3734 22.6673 70.5724 23.6968C69.7715 24.7263 69.3741 26.002 69.3741 27.5178C69.3741 28.8884 69.721 30.1578 70.4148 31.3263C71.1085 32.4947 72.1428 33.442 73.5177 34.162C74.8925 34.882 76.608 35.2357 78.6703 35.2357C80.5308 35.2357 82.0948 34.7999 83.3562 33.922C84.5229 33.1136 85.4122 32.0399 86.0492 30.7326L86.5348 34.541H91.6243V19.2631C91.6243 17.1031 91.1954 15.3284 90.3377 13.9452C89.48 12.562 88.2754 11.5326 86.7303 10.8631H86.724ZM83.2174 30.0063C82.3786 30.5368 81.3569 30.7957 80.1524 30.7957C78.5694 30.7957 77.4152 30.4736 76.69 29.8294C75.9647 29.1852 75.6052 28.421 75.6052 27.5305C75.6052 26.3115 76.053 25.4463 76.9422 24.9347C77.8315 24.4231 78.9667 24.1705 80.3542 24.1705C81.2939 24.1705 82.2967 24.2526 83.3688 24.4168C84.2328 24.5494 85.0338 24.7389 85.7906 24.9599V25.6547C85.7906 26.4441 85.5698 27.2399 85.1347 28.0294C84.6995 28.8189 84.0625 29.482 83.2174 30.0063V30.0063Z" fill="#F7F3F2"/>
            <path d="M115.13 11.4062C113.54 10.3767 111.774 9.8588 109.832 9.8588C108.053 9.8588 106.515 10.282 105.222 11.1346C104.326 11.722 103.569 12.5177 102.958 13.4967V0.599854H97.1238V34.5535H100.927L102.358 30.3599C103.09 31.9577 104.061 33.1704 105.266 33.9851C106.508 34.8251 107.883 35.2483 109.378 35.2483C111.402 35.2483 113.25 34.7177 114.915 33.6504C116.58 32.5893 117.898 31.0925 118.882 29.1725C119.866 27.2525 120.351 25.0041 120.351 22.4272C120.351 19.8504 119.878 17.6714 118.932 15.783C117.986 13.8946 116.719 12.4356 115.13 11.3999V11.4062ZM113.698 26.3935C113.181 27.5177 112.474 28.3893 111.585 29.0146C110.696 29.6399 109.68 29.9556 108.545 29.9556C107.624 29.9556 106.735 29.703 105.878 29.1977C105.02 28.6925 104.32 27.9914 103.777 27.082C103.235 26.1725 102.964 25.1177 102.964 23.9177V21.0441C102.964 19.7935 103.222 18.7199 103.74 17.842C104.257 16.9641 104.938 16.282 105.777 15.8146C106.615 15.3409 107.524 15.1072 108.495 15.1072C109.662 15.1072 110.696 15.4104 111.598 16.023C112.493 16.6356 113.2 17.4883 113.71 18.5872C114.221 19.6862 114.474 20.9683 114.474 22.4335C114.474 23.8988 114.215 25.2693 113.698 26.3935V26.3935Z" fill="#F7F3F2"/>
            <path d="M138.843 10.5536L133.835 27.8084L128.021 10.5536H121.398L131.035 34.56L130.291 36.8842C129.995 37.8758 129.635 38.741 129.206 39.4926C128.777 40.2442 128.273 40.8315 127.686 41.2484C127.1 41.6715 126.419 41.88 125.649 41.88C124.791 41.88 123.94 41.7473 123.089 41.4821C122.237 41.2168 121.361 40.8063 120.459 40.2442V45.3915C121.581 45.9347 122.679 46.3389 123.757 46.5915C124.836 46.8442 125.946 46.9768 127.081 46.9768C129.055 46.9768 130.732 46.3389 132.107 45.0694C133.482 43.8 134.643 41.7473 135.582 38.9052L144.778 10.541H138.849L138.843 10.5536Z" fill="#F7F3F2"/>
        </svg>
        
    )
}