import axios from 'axios'
import storage from '../lib/storage'
import { publish } from 'pubsub-js'
import { API_URL } from './constants'

const fetcher = axios.create({
    baseURL: API_URL
})

export const REDIRECT_FROM_FETCHER = 'fetcher:REDIRECT_FROM_FETCHER' 

const unathorizedInterceptor = (error) => {
    error.response.status === 401 && publish(REDIRECT_FROM_FETCHER)
    return Promise.reject(error)
}

function authorizationInterceptor(config){
    config.headers['Authorization'] = `Bearer ${storage.getToken()}`
    return config 
}

const interceptorError = err => Promise.reject(err)

fetcher.interceptors.request.use(authorizationInterceptor, interceptorError)
fetcher.interceptors.response.use(response => response, unathorizedInterceptor)

export default fetcher
