import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";

const StyledCheckbox = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ colorTheme, selected }) =>
    selected ? colorTheme : "white"};
  border: 2px solid
    ${({ colorTheme, selected }) =>
      selected ? "none" : "rgba(115, 115, 115, 1)"};
`;

export const Checkbox = ({ selected, colorTheme, size }) => {
  return (
    <StyledCheckbox
      colorTheme={colorTheme}
      selected={selected}
      style={{
        width: size || "1rem",
        height: size || "1rem",
      }}
    >
      {selected && <FaCheckCircle color="green" size={24} />}
    </StyledCheckbox>
  );
};
