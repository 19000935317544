import styled from "styled-components";
// import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import React from "react";

const ModalContent = styled.div`
  padding: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 24px;
  text-align: center;

  > button {
    width: 100%;
  }

  > h3 {
    margin: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > p {
      color: #484848;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  * {
    &::selection {
      background: #5d4deb;
      color: white;
    }
  }
`;

export const ModalInfoPix = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} theme={"vlab"}>
      <ModalContent>
        <h3>Como pagar compras com Pix</h3>
        <div>
          <p style={{ color: "#5D4DEB" }}>1º passo</p>
          <p>Copie o código que foi gerado</p>
        </div>
        <div>
          <p style={{ color: "#5D4DEB" }}>2º passo</p>
          <p>
            Abra um aplicativo em que você tenha o Pix habilitado e use a opção
            Pix Copia e Cola
          </p>
        </div>
        <div>
          <p style={{ color: "#5D4DEB" }}>3º passo</p>
          <p>
            Cole o código, confirme o valor e faça o pagamento. Ele será
            confirmado na hora
          </p>
        </div>
        {/* <Button theme="vlab" variant="contained" onClick={onClose}>
          Ok, entendi
        </Button> */}
      </ModalContent>
    </Modal>
  );
};
