import styled, { css } from "styled-components";

const Container = styled.div`
    width: 100%;
    min-height: 100dvh;

    display: grid;
    grid-template-rows: auto 1fr auto;
    justify-items: center;
`;

const Header = styled.header`
    width: 100%;
    padding: 8px 0;
`

const Main = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media (max-width: 586px) {
        width: 100%;
        justify-content: start;
    }
`

const PlayerContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100%;

    @media (max-width: 586px) {
        width: 100%;
    }
`

const Footer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const ContainerVideo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) => (props.isMobile && !props.isLandscape) && css`
        width: 100%;

        display: flex;
        justify-content: center;

        background-color: #FFF;
        padding: 0;
    `}
`;

const SelectLanguageContainer = styled.div`
    background-color: white;
    padding: 12px 24px;
    border-radius: 4px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    color: #5E5E5E;
    width: 18rem;
`

const StyledFloat = styled.div`
    position: fixed;
    bottom: -86px;
    display: flex;
    right: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
        top: 500px;
    }

    @media (max-width: 1023px) and (orientation: landscape) {
        top: 300px;
        right: 20px;
    }
`;

const ContainerLogo = styled.div `
    width: 100%;
    height: 32px;
    ${props => css`
        background-image: url(${props.url});
    `}
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`;

const LabelExam = styled.p`
    color: '#020523';
    font-weight: 700;
    font-family: 'Poppins', sans-serif;    
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    display: flex;
    justify-content: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
`;

const InfoLabel = styled.p`
    color: #8B98A3;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12.9px;
    line-height: 14px;
    letter-spacing: 0.5px;
`;

const ContainerLabel = styled.div`
    display: flex;
    width: 50%;
    margin-left: 32px;
    align-items: end;
    justify-content: flex-end;
    gap: 32px;

    @media (max-width: 928px) {
        margin-left: 0;
        justify-content: center;
        width: 100%;
    }
`;

const ContainerButton = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    gap: 16px;

    @media (max-width: 586px) {
        padding: 0 16px;
    }
`;

const ContainerDescription = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 928px) {
        flex-direction: column;
        justify-content: center;
    }
`;

const ScheduleContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    

    @media (max-width: 586px) {
        padding: 12px;
        margin-bottom: 0;
    }
`;

const ScheduleSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 16px;
    border-top: 1px solid #DDE5EC;
    border-bottom: 1px solid #DDE5EC;

    padding: 16px 0;
    min-height: 160px;
`;

const UnitDescription = styled.p`
    color: '#020523';
    box-sizing: border-box !important;
    font-weight: 700;
    font-family: 'Poppins', sans-serif; 
    font-size: 18px;
`;

const ScheduleContainerButton = styled.div`
    width: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextButton = styled.button`
    color: #0145D5;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background: none;
    border: none;
    padding: 8px 12px;
`;

const SecondButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-radius: 8px;
  font-style: normal;
  color: #8b98a3;
  font-weight: 600;
  border: 1px solid #8b98a3;

  & {
    text-transform: none;
  }
  &:hover {
    background: #F7F8FC;
    cursor: pointer;
  }
  &:active{
    background: #ECF2F8;
  }
  &:disabled {
    background: #f2f2f2;
    color: #DDE5EC;
    border: 1px solid #DDE5EC;
    cursor: not-allowed;
    pointer-events: none;
    img {
      opacity: 0.3;
    }
  }
`;

const PoliciesContainer = styled.div`
    display: flex;
    wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 12px;
    margin-bottom: 24px;

    @media (max-width: 586px) {
        flex-direction: column;
    }
`;

const Divider = styled.div`
    box-sizing: border-box;
    width: 50%;
    height: 1px;
    background-color: #DDE5EC;

    @media (max-width: 586px) {
        width: calc(100% - 48px);
        justify-content: start;
    }
`;

const SelectLanguageButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
`;

export {
    Container,
    Header,
    PlayerContainer,
    ContainerVideo,
    SelectLanguageContainer,
    StyledFloat,
    ContainerLogo,
    LabelExam,
    ContainerLabel,
    ContainerButton,
    ContainerDescription,
    Footer,
    Main,
    InfoLabel,
    ScheduleContainer,
    ScheduleContainerButton,
    ScheduleSection,
    TextButton,
    SecondButton,
    PoliciesContainer,
    Divider,
    SelectLanguageButtonContainer,
    UnitDescription,
}