import React from "react";
import { IoMdClose } from "react-icons/io";
import { Backdrop, ModalContent, CloseIcon } from "./styles";

function Modal({ isOpen, onClose, children, theme, isPasswordModal }) {
  if (!isOpen) return null;

  const handleClose = () => {
    if (!isPasswordModal) {
      onClose();
    }
  };

  return (
    <Backdrop onClick={handleClose} isPasswordModal={isPasswordModal}>
      <ModalContent onClick={(e) => e.stopPropagation()} theme={theme}>
        {!isPasswordModal && (
          <CloseIcon onClick={onClose} theme={theme}>
            <IoMdClose />
          </CloseIcon>
        )}
        {children}
      </ModalContent>
    </Backdrop>
  );
}

export default Modal;
