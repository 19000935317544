import React, { useEffect } from 'react'

import logger from '../../utils/logger'
import PatientFetcher from '../../containers/patient-fetcher'
import PatientContext from '../../contexts/patient-context'
import { withRedirect } from '../../hoc/with-redirect'
import { getPatient } from '../../lib/api'

const RequestPermissionsContainer =
    ({
        children,
        redirectTo
    }) => (
        <PatientFetcher>
            {patientFetcher => {
                const { setPatient } = React.useContext(PatientContext)

                useEffect(() => {
                    requestPatientData()
                }, [])

                async function requestPatientData() {
                    try {
                        const response = await getPatient()
                        
                        setPatient(response)
                        const { payment } = response

                        if (payment && payment.status === 'unknown') {
                            redirectTo('/checkout')
                        } 
                        
                        if (payment && payment.status == 'opened' && payment.bank_slip_url) {
                            redirectTo('/payment-opened/' + encodeURIComponent(payment.bank_slip_url))
                        } 
                        else {
                            redirectTo('/watch')
                        }

                    } catch (error) {
                        logger.error('<RequestPermissionsContainer />', error)
                    }
                }

                return children()
            }}
        </PatientFetcher>
    )
export default withRedirect(RequestPermissionsContainer)
