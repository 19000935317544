import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function CloseButton({ onClick }) {
  return (
    <>
      <IconButton aria-label="close" onClick={onClick}>
        <CloseIcon style={{color: '#B7C6D1'}}/>
      </IconButton>
    </>
  );
}
