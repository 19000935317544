/**
 * Exam status
 */
export const STATUS_LIVE = 'LIVE'
export const STATUS_RECORDED = 'RECORDED'
export const STATUS_UPCOMING = 'UPCOMING'

/**
 * Player status
 */
export const PLAYER_STATE_ENDED = 'ENDED'
export const PLAYER_STATE_IDLE = 'IDLE'
export const PLAYER_STATE_LOADING = 'LOADING'
export const PLAYER_STATE_PAUSED = 'PAUSED'
export const PLAYER_STATE_PLAYING = 'PLAYING'
export const PLAYER_STATE_STOPPED = 'STOPPED'

/**
 * Redirect status
 */
export const INVALID_HASH = 'invalid-token'
