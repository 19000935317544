import React, { useEffect, useState } from "react";
// import Button from "../../../../components/Button";
import { Checkbox } from "../../../../components/Checkbox";
import FlexContainer from "../../../../components/Flex";
import { validateEmail } from "../../../../utils/validateEmail";
import { birthdayMask, cepMask, cpfMask } from "../../../../utils/masks";
import {
  FormContainer,
  Header,
  Divider,
  InputsContainer,
  StyledInput,
} from "./styles";
// import { useApi } from "../../../../hooks/useApi";
// import { useQuery } from "react-query";
import { CardElement } from "@stripe/react-stripe-js";
import { hasLastName } from "../../../../utils/hasLastName";
import { SubmitButton } from "../../../login/components/buttons";
import { getCep } from "../../../../lib/api";
import { useTranslation } from "react-i18next";

const cardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",

      padding: "10px",
      "::placeholder": {
        color: "black",
      },
      ":focus": {
        backgroundColor: "#f7f7f7",
      },
    },
    invalid: {
      color: "red",
      iconColor: "red",
    },
  },
  hidePostalCode: true,
};

export const Form = ({
  currentStep,
  formStep,
  onClickStep,
  nextStep,
  header,
  id,
  children,
  setForm,
  form,
  handleCardChange,
  paymentMethod,
}) => {
  const [hasError, setHasError] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const { t } = useTranslation();

  function handleClickNext() {
    let hasEmptyFields = true;

    if (formStep === 1) {
      if (paymentMethod === "card") {
        hasEmptyFields = !form.email || !form.name;
      } else {
        hasEmptyFields =
          !form.email || !form.name || !form.birthdate || !form.cpf;
      }
    }

    if (formStep === 2) {
      hasEmptyFields =
        !form.city || !form.line1 || !form.country || !form.zip || !form.state;
    }

    if (formStep === 3) {
      nextStep();
      return;
    }

    if (hasEmptyFields || errorFields.length) {
      setHasError(id);
    } else {
      setHasError(null);
      nextStep();
    }
  }

  useEffect(() => {
    if (form?.zip?.length === 9) {
      getCep(form.zip).then((response) => {
        setForm((prev) => ({
          ...prev,
          city: response.data.localidade,
          line1: response.data.logradouro,
          state: response.data.uf,
        }));
        const newErrorFields = errorFields.filter(
          (field) => field !== "street" && field !== "nightborhood"
        );
        setErrorFields(newErrorFields);
      });
    }
  }, [form.zip]);

  return (
    <FormContainer id={id} selected={currentStep === formStep}>
      <Header
        style={{ paddingBottom: currentStep === formStep ? "0" : "16px" }}
        onClick={() => onClickStep(formStep)}
      >
        <p>{header}</p>
        {paymentMethod !== "pix" && (
          <Checkbox selected={currentStep > formStep} />
        )}
      </Header>
      {children ? (
        <>
          <Divider />
          <InputsContainer>{children}</InputsContainer>
        </>
      ) : (
        <>
          {currentStep === formStep && formStep === 1 && (
            <>
              <Divider />
              <InputsContainer>
                <StyledInput
                  autoFocus
                  placeholder="E-mail"
                  value={form.email}
                  onBlur={(e) => {
                    if (!validateEmail(e.target.value)) {
                      const hasError = errorFields.includes("email");
                      if (hasError) return;
                      setErrorFields((prev) => [...prev, "email"]);
                    } else {
                      setErrorFields((prev) =>
                        prev.filter((field) => field !== "email")
                      );
                    }
                  }}
                  isError={errorFields.includes("email")}
                  onChange={(e) => {
                    setForm((prev) => ({ ...prev, email: e.target.value }));
                  }}
                />
                <StyledInput
                  placeholder={t("payment.name")}
                  value={form.name}
                  onBlur={(e) => {
                    const hasLastNameField = hasLastName(e.target.value);
                    if (!hasLastNameField) {
                      setErrorFields((prev) => [...prev, "name"]);
                    } else {
                      setErrorFields((prev) =>
                        prev.filter((field) => field !== "name")
                      );
                    }
                  }}
                  isError={errorFields.includes("name")}
                  onChange={(e) => {
                    setForm((prev) => ({ ...prev, name: e.target.value }));
                  }}
                />
                {paymentMethod !== "card" && (
                  <>
                    <FlexContainer gap="16px">
                      <StyledInput
                        placeholder={t("payment.birthday")}
                        value={birthdayMask(form.birthdate)}
                        onBlur={(e) => {
                          if (e.target.value.length < 10) {
                            setErrorFields((prev) => [...prev, "birthdate"]);
                          } else {
                            setErrorFields((prev) =>
                              prev.filter((field) => field !== "birthdate")
                            );
                          }
                        }}
                        isError={errorFields.includes("birthdate")}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            birthdate: e.target.value,
                          }));
                        }}
                      />
                      <StyledInput
                        placeholder="CPF"
                        maxLength={14}
                        onBlur={(e) => {
                          if (e.target.value.length < 14) {
                            setErrorFields((prev) => [...prev, "CPF"]);
                          } else {
                            setErrorFields((prev) =>
                              prev.filter((field) => field !== "CPF")
                            );
                          }
                        }}
                        isError={errorFields.includes("CPF")}
                        value={cpfMask(form.cpf)}
                        onChange={(e) => {
                          setForm((prev) => ({ ...prev, cpf: e.target.value }));
                        }}
                      />
                    </FlexContainer>
                  </>
                )}

                {hasError === id && (
                  <p style={{ fontSize: "12px", color: "#ff0a0a" }}>
                    {t("payment.fillFields")}
                  </p>
                )}
              </InputsContainer>
              <div style={{ marginLeft: "auto", padding: "0 16px 16px 0" }}>
                <SubmitButton
                  color="#624DEB"
                  fontColor={"white"}
                  onClick={handleClickNext}
                  style={{ width: "230px" }}
                >
                  {t("payment.next")}
                </SubmitButton>
              </div>
            </>
          )}

          {currentStep === formStep && formStep === 2 && (
            <>
              <Divider />
              <InputsContainer>
                <StyledInput
                  autoFocus
                  placeholder="CEP"
                  maxLength={9}
                  onBlur={(e) => {
                    if (e.target.value.length < 9) {
                      setErrorFields((prev) => [...prev, "zip"]);
                    } else {
                      setErrorFields((prev) =>
                        prev.filter((field) => field !== "zip")
                      );
                    }
                  }}
                  isError={errorFields.includes("zip")}
                  value={cepMask(form.zip)}
                  onChange={(e) => {
                    setForm((prev) => ({ ...prev, zip: e.target.value }));
                  }}
                />
                <FlexContainer gap="16px">
                  <StyledInput
                    placeholder={t("payment.city")}
                    value={form.city}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        const hasError = errorFields.includes("city");
                        if (hasError) return;
                        setErrorFields((prev) => [...prev, "city"]);
                      } else {
                        setErrorFields((prev) =>
                          prev.filter((field) => field !== "city")
                        );
                      }
                    }}
                    isError={errorFields.includes("city")}
                    onChange={(e) => {
                      setForm((prev) => ({ ...prev, city: e.target.value }));
                    }}
                  />
                  <StyledInput
                    placeholder={t("payment.state")}
                    value={form.state}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setErrorFields((prev) => [...prev, "state"]);
                      } else {
                        setErrorFields((prev) =>
                          prev.filter((field) => field !== "state")
                        );
                      }
                    }}
                    isError={errorFields.includes("state")}
                    onChange={(e) => {
                      setForm((prev) => ({
                        ...prev,
                        state: e.target.value,
                      }));
                    }}
                    maxLength={2}
                  />
                </FlexContainer>
                <StyledInput
                  placeholder={t("payment.street")}
                  value={form.line1}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      const hasError = errorFields.includes("line1");
                      if (hasError) return;
                      setErrorFields((prev) => [...prev, "line1"]);
                    } else {
                      setErrorFields((prev) =>
                        prev.filter((field) => field !== "line1")
                      );
                    }
                  }}
                  isError={errorFields.includes("line1")}
                  onChange={(e) => {
                    setForm((prev) => ({ ...prev, line1: e.target.value }));
                  }}
                />

                {hasError === id && (
                  <p style={{ fontSize: "12px", color: "#ff0a0a" }}>
                    {t("payment.fillFields")}
                  </p>
                )}
              </InputsContainer>
              <div style={{ marginLeft: "auto", padding: "0 16px 16px 0" }}>
                <SubmitButton
                  color="#624DEB"
                  fontColor={"white"}
                  onClick={handleClickNext}
                  style={{ width: "230px" }}
                >
                  {t("payment.next")}
                </SubmitButton>
              </div>
            </>
          )}

          {currentStep === formStep && formStep === 3 && (
            <>
              <Divider />
              <InputsContainer>
                <div>
                  <CardElement
                    options={cardElementOptions}
                    onChange={handleCardChange}
                  />
                </div>
              </InputsContainer>
            </>
          )}
        </>
      )}
    </FormContainer>
  );
};
